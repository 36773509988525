// import CookieConsent, { Cookies } from "react-cookie-consent";
import { useEffect, useState } from 'react';
import '../../assets/styles/cookies.css';

const CookiePopup = () => {
    const name = 'cookieConsent';
    const [showCookiePopup, setShowCookiePopup] = useState(true);

    useEffect(()=>{
        if(getCookie(name)){
            setShowCookiePopup(false);
        }else{
            setShowCookiePopup(true);
        }  
    });

    const handleButtonClick = (value) => {    
        if (document.cookie.includes(name)){
            // console.log('included', document.cookie);
            // console.log(getCookie(name));
            return;
        };          
        
        const days = value === 'true' ? 30 : 1;
        let date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        const expires = "; expires=" + date.toUTCString();
        
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
        setShowCookiePopup(false);
    }

    const getCookie = (name) => {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    // const eraseCookie = (name) => {   
    //     document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    // }

    return ( 
        <>

            {
                showCookiePopup &&
                <div class="wrapper">
                    <header>
                        <i class="bx bx-cookie"></i>
                        <h2>Cookies Consent</h2>
                    </header>

                    <div class="data">
                        <p>This website use cookies to help you have a superior and more relevant browsing experience on the website. 
                            <a href="https://greatftech.com/legal/cookie-policy" target="_blank" rel="noreferrer noopener"> Read more...</a>
                        </p>
                    </div>

                    <div class="buttons">
                        <button 
                            className="button" 
                            id="acceptBtn"
                            onClick={()=>handleButtonClick('true')}
                        >
                            Accept
                        </button>
                        <button 
                            className="button" 
                            id="declineBtn"
                            onClick={()=>handleButtonClick('false')}
                        >
                            Decline
                        </button>
                    </div>
                </div>
            } 




            {/* <CookieConsent>
                This website uses cookies to enhance the user experience.
            </CookieConsent> */}

            {/* <CookieConsent
                location="bottom"
                buttonText="Sure man!!"
                cookieName="myAwesomeCookieName2"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
                >

            </CookieConsent> */}

            {/* <CookieConsent
                onAccept={(acceptedByScrolling) => {
                    if (acceptedByScrolling) {
                    // triggered if user scrolls past threshold
                    alert("Accept was triggered by user scrolling");
                    } else {
                    alert("Accept was triggered by clicking the Accept button");
                    }
                }}
            ></CookieConsent> */}

            {/* <CookieConsent
            enableDeclineButton
            onDecline={() => {
                alert("nay!");
            }}
            ></CookieConsent> */}
        </>
    );
}

export default CookiePopup;