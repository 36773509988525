import React, { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { faqs as faqReducer } from 'src/redux/faqsSlice';
import { useDispatch, useSelector } from 'react-redux';
import Faq from './faq';
import ReloadPageInstruction from './reloadPageInstruction';

const Faqs = () => {
    const dispatch = useDispatch();

    let faqs = useSelector((state)=> state.faqs);
    let [isLoading, setIsLoading] = useState(false);
    let [isErrorEncountered] = useState(false);

    useEffect(()=>{
        const loadFaqs = async () => {
            try{
                setIsLoading(true);
        
                const res = await axios
                .get(`${process.env.REACT_APP_API_PATH}faqs/get`);
                setIsLoading(false);
                const { error, data } = res.data;
        
                if(!error && Array.isArray(data)){
                    dispatch(faqReducer(data));
                }
            }catch(err){
                console.error(err);
            }
        }


        if(faqs.length < 1){
            loadFaqs();
        }
    }, [faqs.length, dispatch]); 



    if(isLoading){
        return <Skeleton count={3} />
    }

    return (
        <div id="faqs" className="p-3" style={{backgroundColor: '#fff'}}>    
            <div>
                { isErrorEncountered && <ReloadPageInstruction />  }
                {faqs.length > 0 && <h1 className="text-center">FREQUENTLY ASKED QUESTIONS</h1> }
                { faqs.map((faq)=> <Faq key={faq.id} faq={faq} /> ) }
            </div>                             
        </div>
    );
}

export default Faqs;