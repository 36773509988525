import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const WalletBalance = () => {
    const [wallet, setWallet] = useState(0);
    const user = useSelector((state)=> state.user);

    useEffect(()=>{
        loadWallet();
    },[]);

    const loadWallet = async () => {
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_PATH}wallet/getBalance`);
            const {error, data} = res.data;

            if(!error && typeof data === 'object'){
                setWallet(data);
            }
        }catch(err){
            console.error(err);
        }
    }

    if(!user){
        return null;
    }

    return (
        <>
            <div className="col-sm-6 mx-auto m-0 p-0">
                {
                    wallet && 
                    <>
                    Bal: {wallet.currency} {wallet.walletAmount}
                    </>
                }
                &nbsp;&nbsp;
                <Link to="/wallet/fund">Fund Wallet</Link>
            </div>
        </>
    );
}

export default WalletBalance;