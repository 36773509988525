import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Utilities from '../helper/utility';
import Loading from '../helper/loading';
import PaymentWidgets  from '../payment/paymentWidgets';
import PaymentInstructions from '../payment/paymentInstructions';
import Astericks from '../astericks';
import Toast  from '../helper/toast';
import QuickLoginForm from '../auth/quickLoginForm';

export default function FundWallet(){
    const history = useHistory();
    const util = new Utilities();
    const [isInitializingFundWallet, setIsInitializingFundWallet] = useState(false);
    const [message, setMessage] = useState('');
    const [paymentDetails, setPaymentDetails] = useState({});
    const [isFlutterwaveWidgetReady, setIsFlutterwaveWidgetReady] = useState(false);
    const [amount, setAmount] = useState('2000');
    const [currency] = useState('NGN');
    const [canLoadPaymentWidget, setCanLoadPaymentWidget] = useState(false);
    const [isWalletFunded, setIsWalletFunded] = useState(false);
    const [transRef, setTransRef] = useState('');
    const [showModal, setShowModal]=useState(false);    
    const user = useSelector((state)=> state.user);
    const amountArr = [300, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 6000, 7000, 8000, 9000, 10000, 12000, 15000, 20000, 25000, 50000];

    useEffect(()=>{
        const loadPaymentScript = () =>{  
            if(window.getpaidSetup){
                setIsFlutterwaveWidgetReady(true);
                return; 
            }else{ 
                let interval = setInterval(()=>{
                    if(window.getpaidSetup){                    
                        setIsFlutterwaveWidgetReady(true);
                        doClearTimeout(interval);                                      
                    }else{ 
                        util.loadScripts([process.env.REACT_APP_FLUTTERWAVE_JS_SCRIPT_URL], true);
                    }
                }, 1000 * 2) // try check again after set interval time
    
                setTimeout(()=>{ // console.log('ready to stop timing limit exceeded');   
                    doClearTimeout(interval);
                }, 1000 * 60);
            }
        }
        loadPaymentScript();
    });

    const doClearTimeout = (timeout) => {
        clearTimeout(timeout);
    }

    const handleFormSubmit = async (event) =>{
        event.preventDefault();

        if(isNaN(amount)){
            setMessage('Please enter a valid amount');
            return;
        }

        setCanLoadPaymentWidget(true);     

        if(!user){
            setMessage('Please login again to continue');
            return;
          }
  
          try{
              setIsInitializingFundWallet(true);
              const res = await axios.post(`${process.env.REACT_APP_API_PATH}wallet/initiateFundWallet`,{
                  amount
              });
              setIsInitializingFundWallet(false);
              const { error, data } = res.data;
  
              if(!error && typeof data === 'object' && data.hasOwnProperty('transRef') && data.hasOwnProperty('gateways') && Array.isArray(data.gateways) && data.gateways.length > 0){
                  setPaymentDetails(data);
                  setTransRef(data.transRef)
              }
          }catch(err){
              console.error(err);
              setIsInitializingFundWallet(false);
          }
    }

    const updatePaymentStatus = (response) =>{
        // console.log('update pay status: ', response);
        const {message, tx} = response;
        setCanLoadPaymentWidget(false);
        setMessage(message);

        if(!tx){
            return;
        }

        if (tx.chargeResponseCode === "00" || tx.chargeResponseCode === "0"){   
            fundWallet();
        }
    }

    const fundWallet = async () =>{        
        const { transRef } = paymentDetails;
        setIsInitializingFundWallet(true);

        try{
            const res = await axios.post(`${process.env.REACT_APP_API_PATH}wallet/fundWallet`,{
                transRef, 
                amount
            });
            setIsInitializingFundWallet(false);

            const { error, message } = res.data;
            if(!error){
                setTransRef(transRef);
                setMessage(message);
                setIsWalletFunded(true);
                // window.history.push(`/wallet/funding/receipt/${transRef}`); // redirect to wallet receipt page
                history.push(`/wallet/funding/receipt/${transRef}`);
            }
        }catch(err){
            console.error(err);
            setIsInitializingFundWallet(false);
        }
    }    

    if(isInitializingFundWallet){
        return <Loading params={ {msg: 'Please wait...'} }/>
    }

    if(!isFlutterwaveWidgetReady && message){
        return <div>{ message }</div>
    }

    if(
        isFlutterwaveWidgetReady && 
        canLoadPaymentWidget && 
        paymentDetails && 
        transRef && 
        amount && 
        currency && 
        paymentDetails.gateways && 
        Array.isArray(paymentDetails.gateways) && 
        paymentDetails.gateways.length > 0
    ){
        return <PaymentWidgets 
            params={ 
                {...paymentDetails, ...user, ...{amount, currency}} 
            } 
            updatePaymentStatus = { updatePaymentStatus } 
        />
    }

    return (            
        <div className="col-sm-6 mx-auto">
            { message }
            <h3>Enter the amount</h3>            
            <form noValidate onSubmit={ handleFormSubmit } className="my-3">
                <div className="form-group">
                    <label htmlFor="amount">
                        <strong>Amount (NGN):</strong>
                        <Astericks />
                    </label>
                    {/* <input 
                        onChange={ (e)=>{
                            setAmount(e.target.value);
                        } } 
                        name="amount" 
                        value={ amount } 
                        id="amount" 
                        className="form-control h3" 
                        placeholder="e.g 10000" 
                    /> */}

                    <select
                        name="amount"
                        className="form-control h3" 
                        value={amount}
                        onChange={(e)=>{
                            setAmount(e.target.value);
                        }} 
                    >
                        {
                            amountArr.map((amt, i)=>(
                            <option key={i}>{amt}</option>
                        ))
                        }
                  </select>
                    
                </div>           
                
                { 
                    message &&
                    <div className="text-center text-danger my-2">{ message } </div> 
                }

                <div className="text-center">
                    <button 
                        onClick={ ()=> handleFormSubmit } 
                        className="btn btn-success btn-lg col-10 col-sm-6 m-2"
                    >
                        Proceed
                    </button>
                </div>
            </form> 
            
            <PaymentInstructions />  

            {
                isWalletFunded && message && 
                <Toast params={ {type: 'success', message} } /> 
            }      

            <Modal show={showModal || !user} onHide={()=> setShowModal(false)} backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                <Modal.Title>Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <QuickLoginForm 
                    setShowModal={()=> setShowModal(false)} 
                    setShowLogin={()=>{}}
                    setShowRegister={()=>{}}
                /> 

                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={()=> setShowModal(false)}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
