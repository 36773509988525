import { createSlice } from '@reduxjs/toolkit';
const initialState = [];
  
export const networkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    network: (state = initialState, action) => {
      return [
        ...action.payload
      ]
    },
  },
})

export const { network } = networkSlice.actions;

export default networkSlice.reducer;