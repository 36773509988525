import React from 'react';
import Utilities from '../helper/utility';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';

class NewsDetails extends React.Component {
    constructor(props){
        super(props);
        this.util = new Utilities();

        this.state = {
            isLoading: true,
            news: null
        }
    }

    componentDidMount(){
        const slug = this.props?.match?.params?.slug || '';

        axios
        .get(`${process.env.REACT_APP_API_PATH}news/getNewsDetails?slug=${slug}`)
        .then(response => {
            const { error, data } = response.data;

            if(!error){
                this.setState({news: data});
            }
        })
        .catch(error => console.log('error fetching news: ', error))
        .then(()=> this.setState({isLoading: false}));
    }

    render(){
        const { news, isLoading } = this.state;
        
        if(!news){
            return null;
        }

        if(isLoading){
            return <Skeleton count={5} />
        }

        return (
            <div className='bg-light text-dark p-3'> 
                <h2>{ news.news }</h2>      
                <div contentEditable='true' dangerouslySetInnerHTML={{ __html: news.moreDetails }}></div>            
            </div>
        );
    }
}

export default NewsDetails;