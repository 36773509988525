import { ToastContainer, toast, Zoom, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useEffect}  from 'react';

const Toast = ({params}) => {    
    const { message, type } = params;

    useEffect(()=>{
        loadToast(type, message);
    }, [message, type]);

    const loadToast = (type, message) =>{
        const toastConfig = {
            className: '',
            draggable: false,
            position: toast.POSITION.TOP_RIGHT
        };

        switch (type) {
            case 'error':
                toast.error(message, toastConfig);
                break;
        
                case 'success':
                    toast.success(message, toastConfig);
                    break;
       
                case 'info':
                    toast.info(message, toastConfig);
                    break;

                case 'warning':
                    toast.warning(message, toastConfig);
                    break;                    

            default:
                break;
        }
    }

    return (
        <div>
            <>
                <ToastContainer 
                    draggable={true} 
                    transition={Zoom}
                    autoClose={8000}
                />
            </>
        </div>
    );
}

export default Toast;
// export default memo(Toast);
