const ReturnPolicy = () => {
    return (
        <>
        <h3>RETURN POLICY</h3>
        If you’re looking to withdraw your fund or exchange your order for whatever reason, we’re here to help! We offer free withdrawal within 24 hours of funding your wallet or purchase of product.

        <p>
            Please note the following exceptions to our refund policy:
        </p>
        <div className="my-3">
            <ul>
                <li>Data or Airtime that has already been delivered cannot be returned.</li>
                <li>You cannot request for withdrawal that is less than N2000</li>                
            </ul>
        </div>

        <p>
            To initiate a return, please Reply to your order confirmation email to request which products you would like to return.
        </p>

        </>
    );
}

export default ReturnPolicy;