import { createSlice } from '@reduxjs/toolkit';
const initialState = [];
  
export const _9mobileDataSlice = createSlice({
  name: '_9mobileData',
  initialState,
  reducers: {
    _9mobileData: (state = initialState, {payload}) => {
      return [
        ...payload
      ]
    },
  },
})

export const { _9mobileData } = _9mobileDataSlice.actions;

export default _9mobileDataSlice.reducer;