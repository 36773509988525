import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from 'react-redux';
import { user as userSlice } from '../../redux/userSlice';
import Utility from '../helper/utility';

const AutoLogin = (props) => {
  const util = new Utility();
  let dispatch = useDispatch();

  const user = useSelector((state)=> state.user); 
  const { search} = useLocation();
  const key = search.substring(search.indexOf('k=') + 2, search.indexOf('r=')-1 || search.length);
  const route = search.substring(search.indexOf('r=') + 2, search.length);

  useEffect(()=>{
    handleLogin(key);
  },[user]);

  const handleLogin = async (key) => {
    try{  
        const res = await axios
        .post(`${process.env.REACT_APP_API_PATH}auth/quick-login`, {
            username: Math.random().toString(36).substring(2,7)+'@gmail.com', 
            password: Math.random().toString(36).substring(2,7), 
            grantType: 'password',
            key
        });

        const { error, data } = res.data;
  
        if(!error && data){
          const { accessToken, refreshToken } = data;
          if(accessToken && refreshToken){
              util.setCookie('_at', accessToken);                  
              dispatch(userSlice(jwt_decode(accessToken)));
          } 
        }

        // window.history.pushState({}, document.title, "/"); // clears the query params from the url
        window.history.pushState(null, "", window.location.href.split("?")[0]);
        props.history.push(`/${route}`); 
    }catch(err){
      console.error(err);   
    //   window.history.pushState({}, document.title, "/"); // clears the query params from the url
      window.history.pushState(null, "", window.location.href.split("?")[0]);
      props.history.push(`/${route}`);    
    }    
  }

  return <></>

}

export default withRouter(AutoLogin);
