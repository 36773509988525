import React from 'react';
import spinnerImg2 from '../../assets/img/spinner/spinner2.gif';

export default function Loading (props) {    
    const msg = props.params?.msg ? props.params.msg : 'Loading...';
    
    return (
        <div className="text-center">
            <img src={spinnerImg2} alt="Loading..." style={{ width: 50+'px' }} />
            { msg ? <div>{ msg }</div> : null }
        </div>
    );         
}