import SocialButton from "./socialButton";

const SocialLogin = (props) => {
    const facebookAppId = '3132986747022238'; // old
    // const facebookAppId = '826001919046611'; // new
    // const googleAppId = '';
    // const amazonAppId = '';
    // const instagramAppId = '';
    // const githubAppId = '';
    // const linkedInAppId = '';

    const handleSocialLogin = (user) => {
        props.handleSetUser(user, type);
      };
      
      const handleSocialLoginFailure = (err) => {
        console.error(err);
      };

      let { type = 'facebook', buttonText = 'Login with Facebok' } = props;
      let appId;

      switch (type) {
        case 'facebook':
            appId = facebookAppId
            break;  
      
        // case 'google':
        //     appId = 'googleIdHere'
        //     break;  
    
      
        default:
            break;
      }

   return <>
            <SocialButton
                provider={type}
                appId={appId}
                onLoginSuccess={handleSocialLogin}
                onLoginFailure={handleSocialLoginFailure}
                >
                { buttonText }
            </SocialButton>
       </>
}

export default SocialLogin