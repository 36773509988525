import React from 'react';
import { useSelector } from 'react-redux';
import internetImage from '../../assets/img/application/internet.jpg';

const MissionStatement = ()=> {
    const merchant = useSelector((state)=> state.merchant) || {};

    return (
        <div className="my-4 h4">
            <h2 className='text-uppercase'>Mission Statement</h2>
            <div className="row m-0">
                <div className="col-sm-6 my-2">
                    <img src={ internetImage } alt="" className="img-fluid" />
                </div>

                <div className="col-sm-6 my-2" style={{lineHeight: '40px'}}>
                    <strong>{ merchant.appName }'s</strong> mission is to make Data Affordable to all Africans.
                </div>
            </div>
        </div>
    );

}

export default MissionStatement;