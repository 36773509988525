import { createSlice } from '@reduxjs/toolkit';
const initialState = [];
  
export const sliderSlice = createSlice({
  name: 'slider',
  initialState,
  reducers: {
    slider: (state = initialState, action) => {
      return [
        ...action.payload
      ]
    },
  },
})

export const { slider } = sliderSlice.actions;

export default sliderSlice.reducer;