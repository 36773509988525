import React from 'react';

const ReloadPageInstruction = (props) => {
    const msg = props.msg ? props.msg : 'Unable to load content please reload this page'

    return (
        <p 
            className="col-12 text-center " 
            style={{ fontWeight: 'bold' }}
        >
            { msg }
        </p>
    );
}

export default ReloadPageInstruction;