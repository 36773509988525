import { useEffect } from "react";
import { useJwt } from "react-jwt";

const DecodeJWT = (props) => { // deprecated please delete
  const token = props?.params.token;    
  const { decodedToken, 
    // isExpired
   } = useJwt(token);

  useEffect(()=>{ // equivalent to componentDidMount in class components
    if(decodedToken){
      props.handleSetUser(decodedToken);
    }
  });

  return (null);
};

export default DecodeJWT;