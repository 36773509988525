import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CCard, CCardBody, CCardHeader, CCol, CForm, CFormGroup, CValidFeedback, CInvalidFeedback, CInput, CLabel, CRow, CSelect } from '@coreui/react'
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import QuickLoginForm from '../auth/quickLoginForm';
import Register from '../auth/register';
import { useDispatch } from 'react-redux';
// import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import WalletBalance from '../wallet/walletBalance';
import { network } from '../../redux/networkSlice';
import { mtnData } from '../../redux/mtnDataSlice';
import { gloData } from '../../redux/gloDataSlice';
import { airtelData } from '../../redux/airtelDataSlice';
import { _9mobileData } from '../../redux/_9mobileDataSlice';

const DataPlansByNetwork = (props) => {
  let dispatch = useDispatch();
  const [selDataPlan, setSelDataPlan] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [canValidatePhoneNo, setCanValidatePhoneNumber] = useState(true);
   const [show, setShow] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const networks = useSelector((state)=> state.networks);

  const mtnPlans = useSelector((state)=> state.mtnDataPlans);
  const gloPlans = useSelector((state)=> state.gloDataPlans);
  const airtelPlans = useSelector((state)=> state.airtelDataPlans);
  const _9mobilePlans = useSelector((state)=> state._9mobileDataPlans);

  const [dataPlans, setDataPlans] = useState([]);

  const user = useSelector((state)=> state.user);

  const mtnNumbersPrefixes = ['07025', '07026', '0703', '0704', '0706', '0803', '0806', '0810', '0813', '0814', '0816', '0903', '0906'];
  const gloNumbersPrefixes = ['0805', '0807', '0705', '0815', '0811', '0905'];
  const airtelNumbersPrefixes = ['0802', '0808', '0708', '0812', '0701', '0902'];
  const nineMobileNumberPrefixes = ['0809', '0818', '0817', '0909'];

  const [message, setMessage] = useState('');

  const [inputField , setInputField] = useState({
    networkId: '',
    dataTo: '',
    dataPlanId: 0,
  });

  useEffect(()=>{
  
    if(networks.length > 0){
      networks.map((network)=>{
        handleLoadDataPlans(network.id);
      });
    }else{
      handleLoadNetworksAndDataPlans();
    }   
  },[]);

  const setShowModal = (canShow) =>{
  }

  const handleLoadNetworksAndDataPlans = async () => {
    try{
      setIsProcessing(true);
      setMessage('');
      const res = await axios 
      .get(`${process.env.REACT_APP_API_PATH}network`);
      
      setIsProcessing(false);

      const { error, message, data } = res.data;      
      if(!error){
        // setNetworks(data);
        dispatch(network(data));
        // dispatch(setNetworkList([]));
        data.map((network)=>{
          handleLoadDataPlans(network.id);
        });
      }else{
        setMessage(message);
      }
    }catch(err){
      const message = err.response?.data?.message ? err.response?.data?.message : 'Sorry something went wrong';
      setMessage(message);
      setIsProcessing(false);
      setIsProcessing(false);
    }
  }

  const handleLoadDataPlans = async (networkId) => {
    setMessage('');

    try{
      // setIsProcessing(true);
      const res = await axios
      .get(`${process.env.REACT_APP_API_PATH}data/get-data-plans-by-network?networkId=${networkId}`);
      // setIsProcessing(false);
      const { error, message, data } = res.data;
         
      if(!error && data && Array.isArray(data)){
        switch (networkId) {          
          case 1:
            dispatch(mtnData(data)); // push data to redux store 
            break;
          case 2:
            dispatch(gloData(data)); // push data to redux store 
            break;
          case 3:
            dispatch(_9mobileData(data)); // push data to redux store 
            break;
          case 4:
            dispatch(airtelData(data)); // push data to redux store 
            break;                            
          default:
            break;
        }
      }else{
        setMessage(message);
      }
    }catch(err){
      console.error('error: ', err, err.response, err.response?.data);
      const message = err.response?.data?.message;
      setMessage(message);

      console.error(err);
      // setIsProcessing(false);
    }
  }

  const handleFormInputChange = (e)=> {
    const { name, value } = e.target;
    setMessage('');

    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    })); 

    if(name === 'canValidatePhoneNumber'){
      setCanValidatePhoneNumber(!canValidatePhoneNo);
    }

    if(name === 'dataPlanId'){
      handleSetSelectedDataPlan(value)
    }

  }

  const handleBuyData = async (e) => {
    e.preventDefault();
    dataTo = dataTo.replace(/ /g,''); // replace spaces with nothing

    // const result = await axios.get(`${process.env.REACT_APP_API_PATH}application/format-phone?phone=${dataTo}`);
    // let err = result.data.error;
    // let formattedPhone = result.data.data;
  
    // if(!err){
    //   dataTo = formattedPhone;
    // }

		// console.log('p:', phone);
		const prefix1 = dataTo.substring(0, 4).trim();
		const prefix2 = dataTo.substring(0, 3).trim();
	  
		if(prefix1 === '234'){
			dataTo = '0'+dataTo.substring(4, dataTo.length);
		}else if(prefix2 === '234'){
		  dataTo = '0'+dataTo.substring(3, dataTo.length);
		}
	  
		dataTo = dataTo.replace(/ /g,'');


    if(!user){
      setShowLogin(true);
      setShow(true);
      return;
    }

    if(!window.confirm(`You are about to purchase ${selDataPlan.plan_network} ${selDataPlan.plan} data valid for ${selDataPlan.month_validate} @ ${selDataPlan.currency + selDataPlan.amount} to ${dataTo} is this OK?`)){
      return;
    }
     
    try{
      setIsProcessing(true);
      setMessage('');  
      const res = await axios
      .post(`${process.env.REACT_APP_API_PATH}data/buy-data`, { networkId, dataTo, dataPlanId, canValidatePhoneNo })
      setIsProcessing(false);
      const { error, message, data } = res.data;
      setMessage(message);

      // toastMsg = toastMessage ? toastMessage : message;
      // setToastMessage(util.toggleAppendSpaceToString(toastMsg));    

      if(!error && data){              
        window.alert(message);
        setInputField({ networkId: '', dataTo: '', dataPlanId: 0 });
        props.history.push(`/transaction/receipt/${data.transRef}`);
      }else{
        setMessage(message + '. Try Select another data plan of lesser amount');
      }
    }catch(err){
      console.error(err);
      const message = err?.response?.data?.message || err.message;
      setMessage(message);
      setIsProcessing(false);
    }
  }

  const handleResetDataPlansByNetwork = (e) =>{
    let {value } = e.target;
    value = parseInt(value);

    switch (value) {
      case 1:
        setDataPlans(mtnPlans);
        break;
    
      case 2:
        setDataPlans(gloPlans);
        break;
    
      case 3:
        setDataPlans(_9mobilePlans);
        break;
    
      case 4:
        setDataPlans(airtelPlans);
        break;
    
      default:
        setDataPlans([]);
        break;
    }

  }

  const handleSetSelectedDataPlan = (dataPlanId) => {
    dataPlanId = parseInt(dataPlanId);

    const res = dataPlans.filter((dataPlan)=>{
      if(dataPlan.id === dataPlanId){
        return dataPlan;
      }
    })
    setSelDataPlan(res[0]);
  }

  const validatePhoneNumber = (e) => {
    const dataNumber = e.target.value;
    
    if(dataNumber.length > 9){
      const dataToPrefix = dataNumber.substring(0, 4);
      if(selDataPlan?.plan_network === 'MTN' && !mtnNumbersPrefixes.includes(dataToPrefix)){
        setMessage('This number does not look like MTN number. You can continue if you\'re sure')
      }else if(selDataPlan?.plan_network === 'GLO' && !gloNumbersPrefixes.includes(dataToPrefix)){
        setMessage('This number does not look like GLO number. You can continue if you\'re sure');
      }else if(selDataPlan?.plan_network === 'AIRTEL' && !airtelNumbersPrefixes.includes(dataToPrefix)){
        setMessage('This number does not look like AIRTEL number. You can continue if you\'re sure');
      }else if(selDataPlan?.plan_network === '9MOBILE' && !nineMobileNumberPrefixes.includes(dataToPrefix)){
        setMessage('This number does not look like 9Mobile number. You can continue if you\'re sure');
      }
    }
  }

  // if(isProcessing){
  //   return <Skeleton count={3} />
  // }

  let { networkId, dataTo, dataPlanId } = inputField;

  return (
    <CRow className="mt-3">
        <CCol sm="9 mx-auto">
            <CCard>
                <CCardHeader><h2 className="text-center">BUY DATA PLAN</h2></CCardHeader>
                <CCardBody>
                    {
                      user && <WalletBalance />
                    }
                    <CForm className="was-validated" onSubmit={ handleBuyData } > 
                        <CFormGroup>
                            <CLabel htmlFor="network">Network</CLabel>
                              <CSelect name="networkId" value = { networkId } multiple={false} onChange={ (e)=>{ handleFormInputChange(e); handleResetDataPlansByNetwork(e); } } custom required id="network">
                                  <option value="">Select</option>
                                  {
                                      networks && Array.isArray(networks) && networks.length > 0 
                                      ? 
                                      networks.map((network)=> <option key={network.id} value={ network.id }>{ network.name }</option> ) 
                                      : 
                                      null
                                  }
                               </CSelect>
                            <CInvalidFeedback className="help-block">Please select Network</CInvalidFeedback>
                            <CValidFeedback>Looks Good</CValidFeedback>
                        </CFormGroup> 
                      
                        <CFormGroup>
                            <CLabel htmlFor="dataPlan">Data Plan</CLabel>
                              <CSelect name="dataPlanId" multiple={false} onChange={ (e)=>{ handleFormInputChange(e);  } } custom required id="dataPlan">
                                  <option value="">Select</option>
                                  {
                                    dataPlans && dataPlans.map((dataPlan)=> <option key={dataPlan.id} value={ dataPlan.id }>{ `${dataPlan.plan}  ${dataPlan.plan_type || ''} - ${dataPlan.currency}${dataPlan.amount}` }</option> ) 
                                  }
                               </CSelect>
                            <CInvalidFeedback className="help-block">Please select data plan</CInvalidFeedback>
                            <CValidFeedback>Looks Good</CValidFeedback>
                        </CFormGroup> 
                      
                        <CFormGroup>
                            <CLabel htmlFor="dataTo">Phone Number</CLabel>
                            <CInput onChange={ (e)=>{handleFormInputChange(e); validatePhoneNumber(e)} } name="dataTo"  value={ dataTo } className="form-control-success fw-bold" style={{fontWeight: '150px'}} id="dataTo" required placeholder="08012345678"/>
                            <CValidFeedback>Looks good</CValidFeedback>
                            <CInvalidFeedback className="help-block">Please provide phone number to recieve the data</CInvalidFeedback>
                        </CFormGroup>

                        <div className="form-group">
                            <label htmlFor="canValidatePhoneNumber">
                            <input type="checkbox" onChange={ handleFormInputChange } checked={ canValidatePhoneNo } name="canValidatePhoneNumber"  id="canValidatePhoneNumber" value={canValidatePhoneNo} />
                                <strong> Validate this number</strong>
                            </label>                                        
                        </div>                        

                        { message ? <CFormGroup className="text-center">{ message }</CFormGroup> : null }

                        <CFormGroup className="text-center">
                            <button disabled = { isProcessing || !networkId || !dataPlanId || !dataTo } className="btn btn-success btn-lg col-sm-10">
                              {
                                isProcessing ? 'Processing...' : 'Send Data'
                              }
                            </button>
                        </CFormGroup>
                    </CForm>
                </CCardBody>
            </CCard>
        </CCol>                 

          <Modal show={show} onHide={()=> setShow(false)} backdrop="static" keyboard={false} >
            <Modal.Header closeButton>
              <Modal.Title>Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              { showLogin && <QuickLoginForm setShowModal={()=> setShow(false)} setShowLogin={setShowLogin } setShowRegister={setShowRegister} /> }
              { showRegister && <Register setShowModal = { setShowModal } setShowLogin = {setShowLogin } setShowRegister = {setShowRegister} /> }

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=> setShow(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
    </CRow>
  )
}

export default withRouter(DataPlansByNetwork);
