import React from 'react';
import logo from '../assets/img/logos/logo.png'; // with import

export default function Logo(){
    const logoStyle = {
        width: 30+'px',
        height: 30+'px',            
    }

    return (            
        <img src={logo} alt="Logo" style={ logoStyle } />
    );
    
}