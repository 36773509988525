import React from 'react';
import { useSelector } from 'react-redux';
import Utility from '../helper/utility';
import { useLocation } from 'react-router-dom';
import RegisterUserLink from './registerUserLink';

const Welcome = () =>{
    const util = new Utility();
    const merchant = useSelector((state)=> state.merchant) || {};

    const { search} = useLocation();
    const referrerSlug = search.substring(search.indexOf('?r=') + 3,  search.length);

    if(referrerSlug){
        util.setCookie('_referrerSlug', referrerSlug);
    }
    
    return (
        <div>
            <h2>Welcome to { merchant.appName }</h2>
            <p>Please Take 1 minute to register so you can enjoy all the benefits of this platform</p>
            <div className='text-center my-2'><RegisterUserLink /></div>
        </div>
    );
}

export default Welcome;