import { createSlice } from '@reduxjs/toolkit';
const initialState = [];
  
export const gloDataSlice = createSlice({
  name: 'gloData',
  initialState,
  reducers: {
    gloData: (state = initialState, {payload}) => {
      return [
        ...payload
      ]
    },
  },
})

export const { gloData } = gloDataSlice.actions;

export default gloDataSlice.reducer;