import { useSelector } from 'react-redux';
import whatsappImage from '../../assets/img/application/whatsapp_image.gif';

const WhatsappUs = () => {
    const merchant = useSelector((state)=> state.merchant) || {};

    const whatsappUsLink = `https://api.whatsapp.com/send/?phone=${ merchant?.supportWhatsapp }&text=Hello&app_absent=0`

    return (            
        <span className="bg-light p-0" style={{ width: '60px', height: '60px', borderRadius: '50%', bottom: '20px', left: '10px', position: 'fixed' }}>
            <a href={ whatsappUsLink } target="_blank" rel="noreferrer">
                <img 
                    src={ whatsappImage } 
                    alt="WhatsappUs" 
                    className="img-fluid" 
                />
                <span >Whatsapp Us</span>
            </a>                
        </span>
    );
}

export default WhatsappUs;