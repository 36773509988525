import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';

export default function APK(){
    const [show, setShow] = useState(false);

    return <>
            <div>Download the mobile App for Android</div>

            <Button variant="success" onClick={()=> setShow(true)}>
                Download Now
            </Button>

            <Modal
                show={show}
                onHide={()=> setShow(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                  <Modal.Title>Download the Mobile App for Android</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    <a href ={process.env.REACT_APP_APK_PATH} target='_blank' className="text-center h3">Click here to download the mobile app</a>
                  </p>
                  <p>
                    Note: If you get a warning message from google, it is because the app is not yet on google play store. Kindly disregard the message and proceed to download and install the app
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={()=> setShow(false)}>
                      Close
                  </Button>
                </Modal.Footer>
            </Modal>
    </>
}