import React from 'react';
import { useSelector } from 'react-redux';
import internetImage from '../../assets/img/application/internet.jpg';

const VisionStatement = ()=> {
    const merchant = useSelector((state)=> state.merchant) || {};

    return (
        <div className="my-4 h4">
            <h2 className='text-uppercase'>Vision Statement</h2>
            <div className="row m-0">
                <div className="col-sm-6 my-2">
                    <img src={ internetImage } alt="" className="img-fluid" />
                </div>

                <div className="col-sm-6 my-2" style={{lineHeight: '40px'}}>
                    <strong>{ merchant.appName }'s</strong> vision is to make it easier for all Africans to have access to the internet.
                </div>
            </div>
        </div>
    );
    

}

export default VisionStatement;