import { createSlice } from '@reduxjs/toolkit';
const initialState = [];
  
export const faqsSlice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {
    faqs: (state = initialState, action) => {
      return [
        ...action.payload
      ]
    },
  },
})

export const { faqs } = faqsSlice.actions;

export default faqsSlice.reducer;