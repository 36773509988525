import React from 'react';

const Faq = ({faq}) => {

    const { question, answer } = faq;

    return (
        <div className="mt-2 mb-4">
            <div className="">
                <h3> { question } </h3>
            </div>
            <p style={{fontSize: 16+'px'}}> { answer } </p>
        </div>
    );
    
}

export default Faq;