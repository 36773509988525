import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../helper/loading';
import { withRouter } from 'react-router';

const PaymentWidgets = ({params, updatePaymentStatus}) => {
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const merchant = useSelector((state)=> state.merchant) || {};

    useEffect(async ()=>{
        if(typeof window.getpaidSetup === 'undefined') { // checking to be sure if flutterwave function to process payment has been loaded
            setMessage('Unable to proceed. Please try reload the page as this may be due to poor network');
            console.log('Unable to proceed. Please try reload the page as this may be due to poor network')
            return;
        }
        
        const flutPayRes = await doFlutterwavePayment(params);    
        updatePaymentStatus(flutPayRes);
        // console.log('ready to verify', flutPayRes)
    },[]);

    const doFlutterwavePayment = ({ 
        email, 
        firstName, 
        lastName, 
        otherNames, 
        amount, 
        currency, 
        transRef, 
        gateways, 
        paymentTitle, 
        paymentType 
    })=> {
        if(amount <= 0 || isNaN(amount)){
            setMessage('Invalid amount supplied');
            return {message: 'Invalid amount'};
        }

        let subAccounts = [];
        let publicKey = '';

        if(Array.isArray(gateways) && gateways.length > 0){
            gateways.map((gateway)=>{
                const { name } = gateway;

                if(name === "flutterwave"){
                    subAccounts = gateway.subAccounts;
                    publicKey = gateway.publicKey;
                }
                return true;
            });
        }

        return new Promise(resolve => {
            let flutReq = {  
                PBFPubKey: publicKey,
                customer_email: email,
                customer_firstname: firstName,
                customer_lastname: lastName + ' '+ otherNames+  ` to ( ${merchant.appName} )`,
                amount,
                currency,
                txref: transRef,
                subaccounts: subAccounts, 
                // payment_options: 'card',
                payment_options: paymentType,
                custom_title: paymentTitle,
                custom_logo: process.env.REACT_APP_LOGO_PATH,
                onclose: ()=> {
                    setLoading(false);
                    setMessage('Payment modal closed. Please try again or use another payment method');
                    resolve({
                        // message: 'Payment modal has been closed. You may try again or use another payment method'
                        message: ''
                    });
                },         
                callback: response => {          
                    if (response.tx.chargeResponseCode === "00" || response.tx.chargeResponseCode === "0"){   
                        resolve(response);
                    }
                    x.close();
                }
            }

            // console.log('flut Req: ', flutReq);
            const x = window.getpaidSetup(flutReq);
        }); // End of promise
    } 

    return (
        <div>                            
            { loading && <Loading params={{msg: 'Processing...'}} /> }
            { message && <div>{ message }</div> }
        </div>
    );
}
 
export default withRouter(PaymentWidgets);