import {useEffect, useState} from 'react';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useSelector } from 'react-redux';
import Logo from '../logo';

const Receipt4WalletFunding = (props) => {
    const merchant = useSelector((state)=> state.merchant) || {};
    let [isLoading, setIsLoading] = useState(false);
    let [receipt, setReceipt] = useState(null);
    let [payer, setPayer] = useState({});

    useEffect(()=>{
        const loadWalletHistory = async () => {
            try{
                const  transRef = props.match?.params?.transRef;
                setIsLoading(true);
        
                const res = await axios 
                .get(`${process.env.REACT_APP_API_PATH}wallet/history?transRef=${transRef}`)
                setIsLoading(false);
                const { error, data, user } = res.data;
                    
                if(!error && data && typeof data === 'object' && user){
                    document.title = `Receipt - ${data.paymentTitle}`;
                    setReceipt(data);
                    // setMessage(message);
                    setPayer(user)
                }
            }catch(err){
                console.error(err);
                setIsLoading(false);
            }
        }

        loadWalletHistory();
    }, [props.match?.params?.transRef]);


    if(isLoading){
        return <Skeleton count={5} />
    }

    if(!receipt){
        return <h2 className="text-center">No Receipt </h2>;
    }

    const { currency, amount, prevBalance, newBalance, action, transRef, dateTime, isCredit } = receipt;

    return (
        <div>
            <div className="mx-auto col-sm-9" style={{background: '#fff'}}>
                <div className="row py-2 m-0">
                    <div className="col-1"> 
                        <Logo /> 
                    </div>
                    <div className="col">
                        <h3>{merchant.appName}</h3>
                    </div>
                    <div className="col text-right">
                    <button onClick={ ()=> window.print() } className="btn btn-light">
                        Print
                    </button>
                    </div>
                </div>

                <div className="text-center">
                    <h4 className="text-uppercase">WALLET FUNDING</h4>
                    <h5 className="text-danger">RECEIPT</h5>
                </div>                 

                <h4>Details</h4>

                <div className="overflow-x-scroll" style={{ overflowX: 'scroll' }}>
                    <table className="table">
                        <thead>
                            <tr>
                                <td>Customer</td>
                                <th> { payer.firstName }, { payer.lastName } { payer.otherNames} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Payment Title</td>
                                <th>{ action }</th>
                            </tr>
                            <tr>
                                <td>Amount</td>
                                <th>{ amount } { currency } </th>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <th className={ isCredit === 1 ? 'text-success' : 'text-danger' } >{ isCredit === 1 ? 'CREDITED' : null }</th>
                            </tr>
                            <tr>
                                <td>Previous Balance</td>
                                <th>{ currency } { prevBalance } </th>
                            </tr>
                            <tr>
                                <td>New Balance</td>
                                <th>{ currency } { newBalance } </th>
                            </tr>                                                        
                            <tr>
                                <td>Reference</td>
                                <th>{ transRef }</th>
                            </tr>
                            <tr>
                                <td>Date</td>
                                <th>{ dateTime }</th>
                            </tr>  
                        </tbody>
                    </table>
                
                    <p>If you have any issue regarding this payment please contact us</p>

                    <div className="row m-0 mx-auto text-info">
                        <li className="fa fa-envelope mx-2 p-0">{ process.env.REACT_APP_EMAILS }</li>
                        <li className="fa fa-whatsapp mx-2 p-0">{ process.env.REACT_APP_WHATSAPP }</li>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Receipt4WalletFunding;