import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import googleOneTap from 'google-one-tap';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from "jwt-decode";
import { merchant as merchantSlice } from './redux/merchantSlice';
import { user as userSlice } from './redux/userSlice';
import Receipt from './myComponents/payment/receipt';
import Header from './myComponents/navigation/header';
import TheFooter from './myComponents/navigation/footer';
import Home from './myComponents/home';
import './scss/style.scss';
import ContactUs from './myComponents/contactUs/contactUs';
import AboutUs from './myComponents/aboutUs/aboutUs';
import FundWallet from './myComponents/wallet/fundWallet';
import NewsDetails from './myComponents/news/newsDetails';
import Welcome from './myComponents/auth/welcome';
import BuyAirtime  from './myComponents/airtime/buyAirtime';
import WalletHistory from './myComponents/wallet/walletHistory';
import LoginLink from './myComponents/auth/loginLink';
import DataPlansByNetwork from './myComponents/data/dataPlansByNetwork';
import Register from './myComponents/auth/register';
import LoginForm from './myComponents/auth/quickLoginForm';
import AutoLogin from './myComponents/auth/autoLogin';
import AccountActivation from './myComponents/auth/AccountActivation';
import Receipt4WalletFunding  from './myComponents/payment/receipt4WalletFunding';
import ReturnPolicy from './myComponents/policies/returnPolicy';
import CookiePopup from './myComponents/cookie/cookiePopup';
import Utility from './myComponents/helper/utility';


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = ()=>  {
    const dispatch = useDispatch();
    const merchant = useSelector((state) => state.merchant);
    const user = useSelector((state)=> state.user);
    const util = new Utility();

    useEffect(async ()=>{
      if(!merchant){
        const res = await axios.get(`${process.env.REACT_APP_API_PATH}public/merchant`);
        const { error, data } = res.data;
  
        if(!error && typeof data === 'object'){
          localStorage.setItem('publicKey', data.publicKey)
          dispatch(merchantSlice(data));
        }
      }

      if(!user){
        const options = {
          client_id: '882077413250-5m819q80a5aktk54540rt5hn88o1iqhj.apps.googleusercontent.com', // greatftech
          // client_id: process.env.REACT_APP_GOOGLE_ONE_TAP_CLIENT_ID,
          auto_select: false, // optional
          cancel_on_tap_outside: false, // optional
          context: 'signin', // optional
        };
          
        googleOneTap(options, (response) => {
          verifyToken(response.credential);      
        });
      }
    
      const verifyToken = async (token) => {
        try{
          const res = await axios.post(`${process.env.REACT_APP_API_PATH}auth/google-one-tap-login`,{
            token
          });
          const { error, data } = res.data;
    
          if(!error && typeof data === 'object' && data.user && data.accessToken){
            const { user, accessToken } = data;
            util.setCookie('_at', accessToken);    
            util.deleteCookie('_referrerSlug');              
            dispatch(userSlice(jwt_decode(accessToken)));
          }
        }catch(err){
          console.error('err2', err);
        }
      }


    },[]);

    return (      
          <Router>
              <Header />
              <div className=" bg-light p-0">                
                <div className="container py-2">                  
                  <React.Suspense fallback={loading}>                
                    <Switch>                         
                      <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                      <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                      <Route path="/" exact name="Home" component={Home} />
                      <Route path="/welcome" exact name="welcome" render={props => <Welcome {...props}/>} />
                      <Route path="/transaction/receipt/:transRef" exact name="Payment Receipt" render={(props)=>  <Receipt {...props} /> } />
                      <Route path="/contact-us" exact name="Contact Us" render={(props)=>  <ContactUs {...props} /> } />
                      <Route path="/about-us" exact name="About Us" render={(props)=>  <AboutUs {...props} /> } />
                     
                      <Route path="/wallet/fund" exact name="Wallet Fund" render={(props)=>  <FundWallet {...props} /> } />
                      <Route path="/wallet/history" exact name="Wallet History" render={(props)=>  <WalletHistory {...props} /> } />
                      <Route path="/wallet/funding/receipt/:transRef" exact name="Receipt" render={(props)=>  <Receipt4WalletFunding {...props} /> } />
                      
                      <Route path="/news/:slug" exact name="News" render={(props)=>  <NewsDetails {...props} /> } />
                      <Route path="/airtime/buy" exact name="Buy Airtime" render={(props)=>  <BuyAirtime {...props} /> } />
                      <Route path="/data-plans" exact name="Data Plans" render={(props)=>  <DataPlansByNetwork {...props} /> } />
                                            
                      <Route path="/login-link" exact name="Login Link" render={(props)=>  <LoginLink {...props} /> } />
                      <Route path="/register" exact name="Register new user" render={(props)=>  <Register {...props} /> } />
                      <Route path="/login" exact name="Login" render={(props)=>  <LoginForm {...props} /> } />
                      <Route path="/auto-login" exact name="Auto Login" render={(props)=>  <AutoLogin {...props} /> } />
                      <Route path="/account/activate" exact name="Account Activation" render={(props)=>  <AccountActivation {...props} /> } />
                      <Route path="/policy/return" exact name="Return Policy" render={(props)=>  <ReturnPolicy {...props} /> } />
                      
                      <Route path="**" name="Page 404" render={props => <Page404 {...props}/>} />
                    </Switch>                    
                  </React.Suspense>
                </div>
              </div>
              <TheFooter /> 
              <CookiePopup />
          </Router> 
    );
} 

export default App;
