import React from 'react';
import defaultUserImage from '../../assets/img/people/default_user_image.jpg';


const PersonFeedback = (params) => {
    return (
        <div className="text-center my-2 p-2">
            <img src={ params.profileImg?.includes('http') ? params.profileImg : defaultUserImage } alt="img" className="rounded-circle img-fluid" />
            <div>
                <div><strong>{ params.firstName } {params.lastName}</strong> </div>
                <div><blockquote>{ params.comment }</blockquote></div>
            </div>
        </div>
    );
}

export default PersonFeedback;