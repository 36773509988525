import {useState} from 'react';
import axios from 'axios';
import Astericks from '../astericks';
import Loading from '../helper/loading';
import Toast from '../helper/toast';
import customerCareImg from '../../assets/img/application/customer_care.jpg';
import Faqs from '../faqs';
import { useSelector } from 'react-redux';

const ContactUs = () => {
    const [msg, setMsg] = useState('');
    const [toastMsg, setToastMsg] = useState('');
    const [toastType, setToastType] = useState('error');
    const [isLoading, setIsLoading] = useState(false);
    const [canShowToast, setCanShowToast] = useState(false);
    const user = useSelector((state)=> state.user);
    const merchant = useSelector((state)=> state.merchant);

    const initialFormValues = {
        email: user.email,
        names: user.firstName ? `${user.firstName} ${user.lastName} ${user.otherNames}` : '',
        userMessage: '',
    }

    const [inputField, setInputField] = useState(initialFormValues)

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputField((prevState)=>({
          ...prevState,
          [name]: value
        }));
      } 

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        if(names === null || email === null || userMessage === null ){
            setMsg('Some required fields are missing or invalid');
            return;
        }

        try{
            setIsLoading(true);
            setCanShowToast(false);
    
            const res = await axios.post(`${process.env.REACT_APP_API_PATH}users/contactUs`, {
                message: userMessage, email, names
            });
            setIsLoading(false);
            const { error, message} = res.data;
            setMsg(message);

            if(!error){
                setInputField(initialFormValues);
            }else{
                setMsg(message);
                setToastMsg(message);
                setToastType('error');
                setCanShowToast(true);
            }
        }catch(err){
            setIsLoading(false);
            console.error(err);
        }

    }

    const { names, email, userMessage } = inputField;

    if(canShowToast){
        <Toast params={{toastMsg, type: toastType }}/>
    }

    return (
        <div className="py-5">
            <div className="p-3 pt-5 col-sm-9 mx-auto text-center row" style={{background: '#fff'}}>
                
                <h4 className="text-center">Feel free to reachout to us and we'll get in touch with you</h4>

                <div className="col-sm-6">
                    <div className="text-left">
                        <form noValidate onSubmit={ handleFormSubmit }>   
                            <div className="form-group">
                                <label htmlFor="email">Your Email:
                                    <Astericks />   
                                </label>                            
                                <input onChange={ handleInputChange } name="email" value={ email } id="email" className="form-control h4" placeholder="youremail@gmail.com" />
                            </div>

                            <div className="form-group has-error">
                                <label htmlFor="names">Your names: 
                                    <Astericks />
                                </label>
                                <input onChange={ handleInputChange } name="names" value={ names } id="names" className="form-control h4" placeholder="FirstName Surname OtherNames" />
                            </div> 

                            <div className="form-group">
                                <label htmlFor="message">Your Message:
                                    <Astericks />   
                                </label>                            
                                <textarea onChange={ handleInputChange } name="userMessage" value={ userMessage } className="form-control h4" placeholder="Your message" />
                            </div>

                            { isLoading ? <Loading /> : null }

                            <div className="text-center">{ msg }</div>

                            <div className="text-center">
                                <button className="btn btn-success btn-lg col-10 col-sm-6 m-2">Send</button>                            
                                <div className="my-3">
                                    <button type="reset" className="btn btn-danger">Reset</button>
                                </div>
                            </div>
                        </form>             
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className='my-3'>
                        <img src={ customerCareImg } alt="Customer Care" className="img-fluid" />
                    </div>

                    <div className="">
                        <h3>You can also reach us via: </h3> 
                        <ul className="my-2 p-0">
 
                            {
                                [merchant.supportEmail].map((email, i)=>{
                                    return <div key={i} className="my-2">
                                    <div><strong>{email}</strong></div>
                                    <div>Email</div>
                                </div> 
                                })
                            }
                        
                            {
                                [merchant.supportWhatsapp].map((whatsapp, i)=>{
                                    return <div key={i} className="my-2">
                                    <div><strong>{whatsapp}</strong></div>
                                    <div>Whatsapp</div>
                                </div> 
                                })
                            }

                            {
                                [merchant.supportPhone].map((phone, i)=>{
                                    return <div key={i} className="my-2">
                                    <div><strong>{phone}</strong></div>
                                    <div>Tel</div>
                                </div> 
                                })
                            }
                         
                            {
                                [merchant.address].map((address, i)=>{
                                    return <div key={i} className="my-2">
                                    <div><strong>{address}</strong></div>
                                    <div>Address</div>
                                </div> 
                                })
                            }
                         
                        </ul>
                    </div>
                    
                </div> 

                {
                    merchant.canShowFaq && 
                    <div className="mt-5">
                        <Faqs />
                    </div>
                }

            </div>

            { canShowToast ? <Toast params={{toastMsg, type: toastType }}/> : null}
            
        </div>
    );

}

export default ContactUs;