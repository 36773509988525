import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useSelector } from 'react-redux';

export default function Receipt(props){
    let [data, setData] = useState(props.data ? this.props.data : '');
    let [isLoading, setIsLoading] = useState(false);
    let [user, setUser] = useState(null);
    let [message, setMessage] = useState('');
    const merchant = useSelector((state)=> state.merchant) || {};
  
    useEffect(()=>{
        const { transRef } = props.match.params;

        setIsLoading(true);
        setMessage('');

        axios
        .get(`${process.env.REACT_APP_API_PATH}transactions/get?transRef=${transRef}`)
        .then(response =>{ 
            const { error, data, user } = response.data;
            
            if(!error && Array.isArray(data) && data.length > 0){
                let transDetails = data[0];
                const { isProductSent } = transDetails;                
                document.title = `Receipt - ${transDetails.paymentTitle}-${transDetails.creationDateTime}`;
                
                setData(transDetails);
                setUser(user);
                setMessage(`Thank you for patronizing us. We are inspired to serve you this week and always. Your product ${isProductSent ? 'HAS BEEN SENT TO YOU' : 'will be sent to you shortly'}. Enjoy!!!`);
            }
        })
        .catch(err => { // when error occurs
            console.error(err);
            const message = err?.response?.data?.message || err.message;
            setMessage(message);
        })
        .then(()=>{
            setIsLoading(false);
        });

    }, [props.match.params]);

    const copyText = ()=>{
        const text = `${data.paymentTitle.substring(12, paymentTitle.length)} has been sent to ${productTo}. Dial ${balanceCheckerCode} to check balance. Date: ${dataTransferDateTime}`;
        navigator.permissions.query({name: "clipboard-write"}).then(result => {
            console.log('res', result)
        if (result.state === "granted" || result.state === "prompt") {
            updateClipboard(text);
        }
        });
    }

    const updateClipboard = (textToCopy)=> {
        // const { message } = this.state;

        navigator.clipboard.writeText(textToCopy).then(()=> {
            window.alert('Copied successfully');
        }, ()=> {
            // toastMsg = message ? message : 'Unable to copy';
            // this.setState({message: this.util.toggleAppendSpaceToString(toastMsg), canShowToast: true, toastType: 'error'});
        });
    }


    const { paymentTitle, amount, currency, transRef, productTo, creationDateTime, isProductSent, balanceCheckerCode, dataTransferDateTime } = data;

    if(isLoading){
        return <Skeleton count={3} />
    }

    return (
        <div>
            <div className="mx-auto col-sm-9" style={{background: '#fff'}}>
                <div className="row py-2 m-0">
                    <div className="col">
                        <h3>{merchant.appName}</h3>
                    </div>
                </div>

                <div className="text-center">
                    <h4 className="text-uppercase">{ paymentTitle }</h4>
                    <h5 className="text-danger">RECEIPT</h5>
                </div>                            

                <h4>Transaction Details</h4>

                <div className="overflow-x-scroll" style={{ overflowX: 'scroll' }}>
                    <table className="table">
                        <thead>
                            <tr>
                                <td>Reference</td>
                                <th>{ transRef }</th>
                            </tr>
                            {
                                user ?
                                <tr>
                                    <td>User</td>
                                    <th> { user.lastName }, { user.firstName } { user.otherNames} </th>
                                </tr>
                                : null
                            }
                        </thead>
                        <tbody>
                            <tr>
                                <td>Payment Title</td>
                                <th>{ paymentTitle }</th>
                            </tr>
                            <tr>
                                <td>Amount</td>
                                <th>{ currency } { amount }</th>
                            </tr>
                            <tr>
                                <td> Transfer Status</td>
                                <th className={ isProductSent ? 'text-success' : 'text-danger' } >{ isProductSent  ? 'COMPLETED' : 'PENDING' }</th>
                            </tr>
                            {
                                balanceCheckerCode 
                                ? 
                                <tr>
                                    <td>Code</td>
                                    <th>{ balanceCheckerCode }</th>
                                </tr>
                                : null
                            }
                
                            <tr>
                                <td>Data Number</td>
                                <th>{ productTo }</th>
                            </tr>
                            <tr>
                                <td>Date</td>
                                <th>{ creationDateTime }</th>
                            </tr>  
                        </tbody>
                    </table>
                    { message ? <p>{ message }</p> : null }
                    <p>It may take up to 1 minute for this product to get to you depending on your network strength</p>
                    <p>If you have any issue regarding this payment please contact us</p>

                    <div className="row m-0 mx-auto text-info">
                        <li className="fa fa-envelope mx-2 p-0">{ process.env.REACT_APP_EMAILS }</li>
                        <li className="fa fa-whatsapp mx-2 p-0">{ process.env.REACT_APP_WHATSAPP }</li>
                    </div>
                </div>
            </div>
            <div className="col text-right">
                <button onClick={ ()=> window.print() } className="btn btn-light mx-3">
                    Print
                </button>

                {
                    data && <button onClick={ ()=> copyText() } className="btn btn-light">
                        Share as Text
                    </button>
                }
            </div>
        </div>
    );    
}