// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const RegisterUserLink = () => {
    // const referrerSlug = useSelector((state)=> state.utility.referrerSlug);

    return (
        <strong className='bg-danger py-2 px-3'><Link to="/register" className="text-light">Register</Link></strong>
    );
}

export default RegisterUserLink;