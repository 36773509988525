import React from 'react';

class Utility extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }

    loadScripts(urls = [], canReload = false) { // Takes an array of string urls to load. canReload means if the script/file can be reloaded even if it hs been loaded
        // let isLoaded = this.isScriptLoaded(urls[0]); // later we need to modify this to pass the whole array
        let isLoaded = this.isScriptLoaded(urls); // later we need to modify this to pass the whole array
        if (!isLoaded) {
            // let dynamicScripts = urls; // An array of urls
        
            for (let i = 0; i < urls.length; i++) {
                let node = document.createElement('script');
                node.src = urls[i];
                node.type = 'text/javascript';
                node.async = false;
                node.charset = 'utf-8';
                document.getElementsByTagName('head')[0].appendChild(node);
            }
        }else if(canReload){          
          for (let i = 0; i < urls.length; i++) {
              let node = document.createElement('script');
              node.src = urls[i];
              node.type = 'text/javascript';
              node.async = false;
              node.charset = 'utf-8';
              document.getElementsByTagName('head')[0].appendChild(node);
          }
        }   
        return this.isScriptLoaded(urls[0]); // we need to modify this to accept all strings in the array
    }
  
    isScriptLoaded(url = []){ // Takes an array of url string
      let scripts = document.getElementsByTagName("script");
      let isFound = false; 
  
      for (let i = 0; i < scripts.length; ++i) {
          if (scripts[i].getAttribute('src') != null && url.includes(scripts[i].getAttribute('src')) ) {
              isFound = true;
          }
      }
      
      return isFound;
    }

    isDeviceMobileWidth(){
      if(window.matchMedia("(max-width: 767px)").matches){  // The viewport is less than 768 pixels wide 
          return true;
      } else{ // The viewport is at least 768 pixels wide
          return false;
      }
    }
 
  // A method to set cookie
  setCookie(name, value, exdays = 0) {
    let d = new Date();

    if(exdays !== null){
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
    }
    // let unused = exdays !== null ? d.setTime(d.getTime() + (exdays*24*60*60*1000)) : '';
    let expires = exdays > 0 ? "expires="+ d.toUTCString() : '';
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }

  deleteCookie(name) { 
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
  
  // A method to get cookie
  getCookie(cName) {
    let name = cName + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  } 

  checkCookie(cName) {
    let data = this.getCookie(cName);
    return data !== '';
  }

  saveSelectedDataPlan(dataPlan){
    localStorage.setItem('selDataPlan', JSON.stringify(dataPlan));
  }

  getSavedDataPlan(){
    const savedDataPlan = localStorage.getItem('selDataPlan') || null;
    return savedDataPlan ? JSON.parse(savedDataPlan) : null;
  }

  clearSavedDataPlan(){
    localStorage.removeItem('selDataPlan');
  }

  convertEncryptedQueryStringsToObject = (encryptedParams)=>{
    let decString = this.encode(encryptedParams);
    return this.convertQueryStringsToObject(decString);
  }    

  // decode = (encodedString)=>{
  //     let c1=0; 
  //     let c2=0; let c3=0;
  //     let Base64={_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},decode:function(e){var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/[^A-Za-z0-9\+\/\=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},_utf8_encode:function(e){e=e.replace(/\r\n/g,"\n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},_utf8_decode:function(e){var t="";var n=0;var r=c1=c2=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}}
  //     return Base64.decode(encodedString); 
  // }

  // encode(stringParam){
  //   let c1=0;
  //   let c2=0;
  //   let c3=0;
  //   let Base64={_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},decode:function(e){var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/[^A-Za-z0-9\+\/\=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},_utf8_encode:function(e){e=e.replace(/\r\n/g,"\n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},_utf8_decode:function(e){var t="";var n=0;var r=c1=c2=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}}

  //   return Base64.encode(stringParam);
  // }

  convertQueryStringsToObject = (queryString)=>{
    if(queryString.substring(0,1) === "?"){
      queryString = queryString.substring(1, queryString.length);
    }

    let objParams = JSON.parse('{"' + decodeURI(queryString).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
    return objParams;
  }

  saveDataInLocalStorage(name, value){
    localStorage.setItem(name, value);
  }

  getDataInLocalStorage(name){
    return localStorage.getItem(name);
  }

  toggleAppendSpaceToString(message){ // add or remove space at the end of a string used for toast message
    let lastChar = message.substring(message.length - 1, message.length);
    message = lastChar === ' ' ? message.substring(0, message.length - 1) : message + ' '
    return message;

  }

  removeAllSpacesFromString(text){
    return text.replace(/ /g,'');
  }

} // end of class Utility

export default Utility;
