import React from 'react';
import {Alert, Button} from "react-bootstrap";
import Utility from '../helper/utility';

class AlertDismissible extends React.Component {
    constructor(props){
        super(props);
        this.util = new Utility();

        this.state = {
            show: window.screen.width > 500 ? true : false,
            dismiss: false,
            maxContentLength: 100,
            canShowAlert: true,
            disabledAlerts: this.util.checkCookie('da') ? JSON.parse(this.util.getCookie('da')) : []
        }
    }

    componentDidMount(){
      const { disabledAlerts } = this.state;
      disabledAlerts.map((alertHeading)=>{
        if(alertHeading === this.props?.params?.heading){
          this.setState({dismiss: true, show: false});
        }
      });
    }

    setShow = (show) => {
        if(this.props.params.isDismissible === 1 && !show){
          this.setState({dismiss: true})
        }

        this.setState({show});
    }

    hamdleClose = (e) => {
      console.log(e.target)
    }

    dismissAlert = (alertHeading) =>{
      const { disabledAlerts } = this.state;
      disabledAlerts.push(alertHeading);
      this.setState({dismiss: true, show: false});
      this.util.setCookie('da', JSON.stringify(disabledAlerts));
    }

    render(){
        const { show, dismiss, maxContentLength } = this.state;
        const { alertType, heading, content } = this.props?.params;
  
        if (show) {
          return (
            <Alert variant={ alertType } onClose={() => this.setShow(false)} 
            // dismissible
            >
              <Button onClick={() => this.setShow(false)} className="btn btn-sm btn-danger float-right">&times;</Button>;
              <Alert.Heading>{ heading }</Alert.Heading>
              <p>{ content.substring(0,maxContentLength) }</p>
              {
                content.length > maxContentLength ? <Alert.Link href="#">Learn more</Alert.Link> : null
              }

              <p className="row my-2 text-center">
                <button onClick={ ()=>{ this.dismissAlert(heading) } } className="btn btn-sm btn-primary mx-auto">Got it</button>
              </p>

            </Alert>
          );
        }else{
            return dismiss ? null : <Button onClick={() => this.setShow(true)} className="btn btn-sm btn-info m-2">{ heading }</Button>;
        }
    }
  }
  
  export default AlertDismissible;