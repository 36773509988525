import { useEffect, useState } from 'react';
import { CButton, CCardBody,CCollapse } from '@coreui/react';
import { CBadge, CDataTable } from '@coreui/react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import QuickLoginForm from '../auth/quickLoginForm';

const WalletHistory = () => {
    let [details, setDetails] = useState([]);
    let [isLoading, setIsLoading] = useState(false);
    let [userWalletHistory, setUserWalletHistory] = useState([]);
    const [showModal, setShowModal]=useState(false);    
    const user = useSelector((state)=> state.user);

    useEffect(()=>{
        if(user){ 
            loadWalletHistory();
        }
    }, [user]);

    const loadWalletHistory = async () => {
        try{
            setIsLoading(true);        
            const res = await axios.get(`${process.env.REACT_APP_API_PATH}wallet/history`);
            setIsLoading(false);
            const { error, data } = res.data;
    
            if(!error && data && Array.isArray(data) && data.length > 0){
                setUserWalletHistory(data); 
            } 
        }catch(err){
            setIsLoading(false);
            console.error(err);
        }
    }

    const toggleDetails = (index) => {
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
        newDetails.splice(position, 1)
    } else {
        newDetails = [...details, index]
    }
        setDetails(newDetails)
    }

    const fields = [
        { key: 'action', _style: { width: '40%'} },
        'amount',
        { key: 'prevBalance', _style: { width: '20%'} },
        { key: 'newBalance', _style: { width: '20%'} },
        { key: 'isCredit', _style: { width: '5%'} },
        { key: 'dateTime', _style: { width: '15%'} },
        {
            key: 'show_details',
            label: '',
            _style: { width: '1%' },
            sorter: false,
            filter: false
        }
    ]

    return (
        <div>
            <h2 className="text-center">Your Wallet History </h2>

            <CDataTable
                items={userWalletHistory}
                fields={fields}
                columnFilter
                tableFilter
                footer
                itemsPerPageSelect
                // default:
                // itemsPerPageSelect = { label: 'Items per page:',  values : [5, 10, 20, 50, 100] }
                itemsPerPage={10}
                hover
                sorter
                pagination
                loading = { isLoading }
                scopedSlots = {{
                'isCredit':
                    (item)=>(
                    <td>
                        <CBadge color={item.isCredit === 1 ? 'success' : 'danger'}>
                        {item.isCredit === 1 ? 'Credit' : 'Debit' }
                        </CBadge>
                    </td>
                    ),
                'show_details':
                    (item, index)=>{
                    return (
                        <td className="py-2">
                        <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={()=>{toggleDetails(index)}}
                        >
                            {details.includes(index) ? 'Hide' : 'Show'}
                        </CButton>
                        </td>
                        )
                    },
                'details':
                    (item, index)=>{
                        return (
                            <CCollapse show={details.includes(index)}>
                            <CCardBody>
                                <p className="text-muted">
                                    <span>Reference No: </span>
                                    <strong>{ item.transRef }</strong>
                                </p>

                                { item.isCredit === 0 ?
                                    <CButton size="sm" color="success">
                                        <Link to={"/transaction/receipt/"+ item.transRef} className="text-light">View Receipt</Link>                                                                           
                                    </CButton>
                                    : null                                    
                                }
                            </CCardBody>
                            </CCollapse>
                        )
                    }
                }}
            />

            <Modal show={showModal || !user} onHide={()=> setShowModal(false)} backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                <Modal.Title>Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <QuickLoginForm 
                    setShowModal={()=> setShowModal(false)} 
                    setShowLogin={()=>{}}
                    setShowRegister={()=>{}}
                /> 

                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={()=> setShowModal(false)}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default WalletHistory;