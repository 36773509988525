import { configureStore, combineReducers } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import mtnDataSlice from './mtnDataSlice';
import gloDataSlice from './gloDataSlice';
import airtelDataSlice from './airtelDataSlice';
import _9mobileDataSlice from './_9mobileDataSlice';
import userSlice from './userSlice';
import referrerSlugSlice from './referrerSlugSlice';
import networkSlice from './networkSlice';
import faqsSlice from './faqsSlice';
import slidersSlice from './slidersSlice';
import merchantSlice from './merchantSlice';

const persistConfig = {
  key: 'root', 
  storage,
}

const rootReducer = combineReducers({
  mtnDataPlans: mtnDataSlice,
  gloDataPlans: gloDataSlice,
  airtelDataPlans: airtelDataSlice,
  _9mobileDataPlans: _9mobileDataSlice,
  user: userSlice,
  referrerSlug: referrerSlugSlice,
  networks: networkSlice,
  faqs: faqsSlice,
  sliders: slidersSlice,
  merchant: merchantSlice
});
 
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware()
    .concat([thunk])
});

export const persistor = persistStore(store)