import React from 'react';
import Utility from '../helper/utility';
import { useDispatch, useSelector } from 'react-redux';
import { user as userReducer} from '../../redux/userSlice';
import axios from 'axios';

const Logout = () =>{
    const util = new Utility();
    const dispatch = useDispatch();

    const user = useSelector((state)=> state.user);

    const logout = () =>{
        doServerLogout();
        doFrontEndLogout();
    }

    const doServerLogout = async () => {
        axios
        .post(`${process.env.REACT_APP_API_PATH}auth/logout`);

    }

    const doFrontEndLogout = () => {                
        util.deleteCookie('_at');
        localStorage.removeItem('_u');
        dispatch(userReducer(null));
        // we can use toastr later
    }

    if(user){
        return <button onClick={ logout } className="btn btn-danger btn-sm">Logout</button>
    }else{
        return null;
    }
}

export default Logout;