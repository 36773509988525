import React, {useState, useEffect} from 'react';
import axios from 'axios';
import AlertDismissible from '../alerts/alertDismissible';
import Utility from '../helper/utility';

const Announcement = () => {
    const util = new Utility();
    const [annoucements, setAnnouncements] = useState([]);

    useEffect(()=>{
        let doUserWantAnnoucements = util.checkCookie('aa') ? JSON.parse(util.getCookie('aa')): true;
        if(!doUserWantAnnoucements){
            return;
        }else{
            loadAnnouncements();
        }
    },[]);

    const loadAnnouncements = async () => {
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_PATH}application/announcements`);

            const { error, data } = res.data;
      
            if(!error){
              util.setCookie('aa', 'true') // aa => Allow Announcement
              setAnnouncements(data);
            }
        }catch(err){
            console.error(err);
        }
    }
  
    if(annoucements.length < 1){
        return null;
    }
    
    return (  
        <div className="py-5 col-lg-2 col-md-3 col-sm-4" style={{ position: 'fixed', zIndex: '1' }}>
            {
                annoucements.map((announcement)=> <AlertDismissible key={announcement.id} params={announcement} />)
            }

        </div>
    );
    
}

export default Announcement;