import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import jwt_decode from "jwt-decode";
import { CCard, CCardBody, CCardHeader, CCol, CForm, CFormGroup, CValidFeedback, CInvalidFeedback, CInput, CLabel, CRow } from '@coreui/react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Toast from '../helper/toast';
import Utility from '../helper/utility';
import { user as userReducer} from '../../redux/userSlice';
import SocialLogin from './socialLogin/socialLogin';

const Register = (props) => {
  const merchant = useSelector((state)=> state.merchant) || {};
  const util = new Utility();
  let dispatch = useDispatch();
  const [processing, setProcessing] = React.useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [message, setMessage] = useState('');

  const [inputField , setInputField] = useState({
    fullName: '',
    email: '',
    phone: '',
    password: '',
    passwordAgain:'',
    referrerSlug: util.getCookie('_referrerSlug'),
  });

  const [terms, setTerms] = useState(true);
  const [privacyPolicy, setPrivacyPolicy] = useState(true);
  const [cookie, setCookie] = useState(true);
  const [promo, setPromo] = useState(true);

  const handleFormInputChange = (e)=> {
    const { name, value } = e.target;
    setMessage('');

    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    })); 

  }

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const firstName = fullName.indexOf(' ') > 0 ? fullName.substring(0, fullName.indexOf(' ')) : fullName;
    let lastName =  fullName.indexOf(' ') > 0 ? fullName.substring(fullName.indexOf(' ') + 1, fullName.length) : '';

    if(password !== passwordAgain){
      setMessage('Password Mismatch');
    }

    lastName = lastName.length > 2 ? lastName : lastName+'...';

    handleRegister({firstName, lastName, email});

  }

  const handleRegister = async ({email, firstName, lastName, avatar, isSocialUser}) => {
    setProcessing(true);

    try{
      const res = await axios
      .post(`${process.env.REACT_APP_API_PATH}auth/register`, {
          firstName, 
          lastName, 
          email, 
          phone, 
          password, 
          referrerSlug, 
          avatar, 
          isSocialUser, 
          service: merchant.appName,
          redirectUrl: merchant.url,
          terms,
          privacyPolicy,
          cookie,
          promo
      });
      setProcessing(false);

      const { error, message, canVerifyEmail, data } = res.data;
      setMessage(message);
      let toastMsg = message ? message : 'Please register';
      setToastMessage(util.toggleAppendSpaceToString(toastMsg));

        if(!error){
           if(canVerifyEmail){              
              if(props.history){
                props.history.push(`/account/activate`);
              }else{
                console.log('no history', props.history);
                window.location.href = window.location.origin+'/account/activate';
              }
    
           }else if(data.accessToken){
            const { accessToken } = data;

            util.setCookie('_at', accessToken);    
            util.deleteCookie('_referrerSlug');
            
            const user = jwt_decode(accessToken); // decode tokens              
            dispatch(userReducer(user)); // push data to redux store 
            if(props.history){
              props.history.push('/');
            }
           }
        }
    }catch(err){
      console.error(err);
      setProcessing(false);
      const message = err?.response?.data?.message || err.message;
      setMessage(message);
    }
  }

  const handleSetUser = (user) => {
    if(user && typeof user === 'object'){
      let { _profile, _provider } = user;
      const {firstName, lastName, email, profilePicUrl } = _profile;
      handleRegister({email, firstName, lastName, avatar: profilePicUrl, provider: _provider.toUpperCase(), isSocialUser: true });
    }
  }

const { email, phone, fullName, referrerSlug, password, passwordAgain } = inputField;
  return (
    <CRow>
        <CCol sm="12 mx-auto">
            <div className="text-center h5 my-3">
              <SocialLogin type="facebook" buttonText="Register with Facebook" handleSetUser={handleSetUser} />
            </div>

            { message && <CFormGroup className="text-center">{ message }</CFormGroup> }

            <CCard>
                <CCardHeader>
                Quickly register to get started. It takes just 2 minutes
                </CCardHeader>
                <CCardBody>
                    <CForm className="was-validated" onSubmit={ handleFormSubmit } > 
                        <div className='row m-0'>
                          <CFormGroup className="col-sm-6">
                              <CLabel htmlFor="full_name">Full Name</CLabel>
                              <CInput onChange={ handleFormInputChange } name="fullName"  value={ fullName } className="form-control-success" id="full_name" required placeholder="John Doe"/>
                              <CValidFeedback>Looks good</CValidFeedback>
                              <CInvalidFeedback className="help-block">Please enter your names</CInvalidFeedback>
                          </CFormGroup>

                          <CFormGroup className="col-sm-6">
                              <CLabel htmlFor="email">Email</CLabel>
                              <CInput onChange={ handleFormInputChange } name="email"  value={ email } className="form-control-success" id="email" required placeholder="johndoe@gmail.com"/>
                              <CValidFeedback>Looks good</CValidFeedback>
                              <CInvalidFeedback className="help-block">Please enter your email</CInvalidFeedback>
                          </CFormGroup>

                          <CFormGroup className="col-sm-6">
                              <CLabel htmlFor="phone">Phone</CLabel>
                              <CInput onChange={ handleFormInputChange } name="phone"  value={ phone } className="form-control-success" id="phone" required placeholder="08012345678"/>
                              <CValidFeedback>Looks good</CValidFeedback>
                              <CInvalidFeedback className="help-block">Please enter your phone number</CInvalidFeedback>
                          </CFormGroup>

                          <CFormGroup className="col-sm-6">
                              <CLabel htmlFor="password">Password</CLabel>
                              <CInput type="password" onChange={ handleFormInputChange } name="password"  value={ password } className="form-control-success" id="password" min={6} required placeholder="Enter Your Password"/>
                              <CValidFeedback>Looks good</CValidFeedback>
                              <label>Use 6 or more characters, numbers & symbols</label>
                              <CInvalidFeedback className="help-block">Please provide your password</CInvalidFeedback>
                          </CFormGroup>

                          <CFormGroup className="col-sm-6">
                              <CLabel htmlFor="password-again">Password Again</CLabel>
                              <CInput type="password" onChange={ handleFormInputChange } name="passwordAgain"  value={ passwordAgain } className="form-control-success" id="password-again" required placeholder="Enter Your Password Again"/>
                              <CValidFeedback>Looks good</CValidFeedback>
                              <CInvalidFeedback className="help-block">Please provide your password again</CInvalidFeedback>
                          </CFormGroup>

                          {/* <CFormGroup className="col-sm-6">
                              <CLabel htmlFor="referrer_slug">Referrer Slug</CLabel>
                              <CInput onChange={ handleFormInputChange } name="referrerSlug"  value={ referrerSlug } className="form-control-success" id="referrer_slug"/>
                          </CFormGroup> */}

                          <CFormGroup className="col-12">                            
                              <CLabel htmlFor="terms">
                              <input type="checkbox" onChange={(e)=> setTerms(e.target.checked) } name="terms" id="terms" checked={terms} />
                              &nbsp; I accept the &nbsp;
                              <a href="https://accounts.greatftech.com/legal/terms" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                              </CLabel>
                          </CFormGroup>

                          <CFormGroup className="col-12">                            
                              <CLabel htmlFor="privacyPolicy">
                              <input type="checkbox" onChange={(e)=> setPrivacyPolicy(e.target.checked) } name="privacyPolicy" id="privacyPolicy" checked={privacyPolicy} />
                              &nbsp; I accept the &nbsp;
                              <a href="https://accounts.greatftech.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                              </CLabel>
                          </CFormGroup>

                          <CFormGroup className="col-12">                            
                              <CLabel htmlFor="cookie">
                              <input type="checkbox" onChange={(e)=> setCookie(e.target.checked) } name="cookie" id="cookie" checked={cookie} />
                              &nbsp; I accept the &nbsp;
                              <a href="https://accounts.greatftech.com/legal/cookie-policy" target="_blank" rel="noopener noreferrer">Cookie Policy</a>
                              </CLabel>
                          </CFormGroup>

                          <CFormGroup className="col-12">                            
                              <CLabel htmlFor="promo">
                              <input type="checkbox" onChange={(e)=> setPromo(e.target.checked) } name="promo" id="promo" checked={promo} />
                              &nbsp; I accept to occasionally recieve mails 
                              </CLabel>
                          </CFormGroup>

                        </div>
                        { message && <CFormGroup className="text-center">{ message }</CFormGroup> }

                        <CFormGroup className="text-center">
                            <button disabled = { processing} className="btn btn-success btn-lg col-sm-10">
                              { processing ? 'Registering...' : 'Create my Account' }
                            </button>
                        </CFormGroup>

                        <div className="my-2 text-center">
                          Already have an account? 
                          <strong>
                              {
                                props.setShowLogin ? 
                                <Link to="#" onClick={()=> { props.setShowLogin(true); props.setShowRegister(false) }}>Login</Link>
                                :
                                <Link to="/login">Login</Link>
                              }
                          </strong>
                        </div>
                    </CForm>
                </CCardBody>
            </CCard>
        </CCol>    

        { toastMessage && <Toast params={{ type: 'success', message: toastMessage }}/> }
    </CRow>
  )
}

export default Register;
