import React from 'react';
import { useSelector } from 'react-redux';
import browsingImg from '../../assets/img/application/browsing.jpg';

const WhyUs = ()=> {
    const merchant = useSelector((state)=> state.merchant) || {};

    return (
        <div className="my-4 h4">
            <h2 className='text-uppercase'>Making Data Affordable for you</h2>
            <div className="row m-0">
                <div className="col-sm-6 my-2">
                    <img src={ browsingImg } alt="" className="img-fluid" />
                </div>

                <div className="col-sm-6 my-2" style={{lineHeight: '40px'}}>
                    Africa is far behind many other continents in Technology and developement. The Internet has redefined how knowledgge is acquired and transfered. To have access to the internet you need data. To change the narative of Africans Technologically, we must give our people access to the Internet. This is what <strong >{ merchant.appName }</strong> is here to do. Giving Nigerians/African affordable and durable data plans.
                </div>
            </div>
        </div>
    );
}

export default WhyUs;