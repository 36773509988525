import React from 'react';
import Toast  from './toast';
import Utility from './utility';

class CopyText extends React.Component{
    constructor(props){
        super(props);
        this.util = new Utility();
        
        this.state = {
            textToCopy: this.props?.params?.textToCopy || '',
            buttonText: this.props?.params?.buttonText || 'Copy',
            toastType: 'error',
            canShowToast: false,
            message: '',                        
        }
    } 
    
    copyText = (text)=>{
        navigator.permissions.query({name: "clipboard-write"}).then(result => {
        // console.log('re: ', result);
        if (result.state === "granted" || result.state === "prompt") {
            /* write to the clipboard now */
            // console.log('ready');
            this.updateClipboard(text);
        }
        });
    }

    updateClipboard(textToCopy) {
        // this.setState({canShowToast: !this.state.canShowToast});
        let toastMsg = ''
        const { message } = this.state;
        // setToastMessage(util.toggleAppendSpaceToString(toastMsg));

        navigator.clipboard.writeText(textToCopy).then(()=> {
            /* clipboard successfully set */
            // console.log('copied');
            // toastMsg = message ? message : 'Copied to clipboard';
            toastMsg = message ? message : 'Your referrer link has been copied ';
            this.setState({message: this.util.toggleAppendSpaceToString(toastMsg), canShowToast: true, toastType: 'success'});
            this.removeToast();
        }, ()=> {
            /* clipboard write failed */
            // console.log('failed'); 
            toastMsg = message ? message : 'Unable to copy';
            this.setState({message: this.util.toggleAppendSpaceToString(toastMsg), canShowToast: true, toastType: 'error'});
        });
    }

    removeToast = () =>{
        setTimeout(()=>{
            this.setState({canShowToast: false});
        },1000 * 10)
    }


    render(){
        const { textToCopy, buttonText, toastType, canShowToast, message } = this.state;

        return (
            <span>
                <button onClick={()=> this.copyText(textToCopy) } className="btn-success" style={{fontSize: '12px'}}>{ buttonText }</button>
                { canShowToast ? <Toast params={{type: toastType, message}} /> : null }
            </span>            
        );
    }
}

export default CopyText;

