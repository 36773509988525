import React, { Component } from 'react';

class Astericks extends Component {
    render(){
        return (
            <span className="text-danger fw-bold">*</span>
        );
    }
}

export default Astericks;