import { createSlice } from '@reduxjs/toolkit';
const initialState = [];
  
export const mtnDataSlice = createSlice({
  name: 'mtnData',
  initialState,
  reducers: {
    mtnData: (state = initialState, {payload}) => {
      return [
        ...payload
      ]
    },
  },
})

export const { mtnData } = mtnDataSlice.actions;

export default mtnDataSlice.reducer;