import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useDispatch } from 'react-redux';
import { user } from '../../redux/userSlice';
import Toast from '../helper/toast';
import Utility from '../helper/utility';
import SocialLogin from './socialLogin/socialLogin';
import { CCard, CCardBody, CCol, CForm, CFormGroup, CValidFeedback, CInvalidFeedback, CInput, CLabel, CRow } from '@coreui/react'

const QuickLoginForm = ({setShowLogin, setShowModal, setShowRegister}) => {
  const util = new Utility();
  let dispatch = useDispatch();

  const [isLoginIn, setIsLoginIn] = React.useState(false);
  const [message, setMessage] = useState('');
  const [toastMessage, setToastMessage] = useState('');

  const [inputField , setInputField] = useState({
    username: '',
    password: '',
  });

  const handleFormInputChange = (e)=> {
    const { name, value } = e.target;
    setMessage('');

    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    })); 
  }

  const handleSetUser = (user) => {
    if(user && typeof user === 'object'){
      let { _profile, _provider } = user;
      handleLogin(_profile.email, 'social', _provider.toUpperCase() );
    }
  }

  const handleSubmitForm = (e) => {
    e.preventDefault();    
    handleLogin(username)
  }

  const handleLogin = async (username, grantType = 'password', provider = '') => {
    try{
      setIsLoginIn(true); 
      setMessage('');
      const referrerSlug = util.getCookie('_referrerSlug');
  
        const res = await axios
        .post(`${process.env.REACT_APP_API_PATH}auth/quick-login`, {
            username, password, referrerSlug, grantType, provider
        });
        setIsLoginIn(false);

        const { error, message, data } = res.data;
  
        setMessage(message);
        let toastMsg = message ? message : 'Please login to continue';
        setToastMessage(util.toggleAppendSpaceToString(toastMsg));

        if(!error && data){
          const { accessToken, refreshToken } = data;
          if(accessToken && refreshToken){
              util.setCookie('_at', accessToken);    
              util.deleteCookie('_referrerSlug');              
              dispatch(user(jwt_decode(accessToken)));

              //clears the form
              setInputField({username: '', password: ''});
              setShowModal(false);
              setShowLogin(false);
              setShowRegister(false);
          }
        }else{
          setMessage(message);
        }

    }catch(err){
      const message = err?.response?.data?.message || err.message;

      setIsLoginIn(false);
      setMessage(message);
      console.error(err);
    }
  }

const { username, password } = inputField;
  return (
    <CRow>
        <CCol sm="12 mx-auto">
            <div className="text-center h5 my-3">
              <SocialLogin type="facebook" handleSetUser={handleSetUser} />
            </div>
            <CCard>                
                <div className="text-center h5 my-3">Or enter your username and password</div>

                <CCardBody>
                    <CForm className="was-validated" onSubmit={ handleSubmitForm } > 
                        <CFormGroup>
                            <CLabel htmlFor="username">Email</CLabel>
                            <CInput onChange={ handleFormInputChange } name="username"  value={ username } className="form-control-success" id="username" required placeholder="Enter Your Email"/>
                            <CValidFeedback>Looks good</CValidFeedback>
                            <CInvalidFeedback className="help-block">Please provide a valid email</CInvalidFeedback>
                            {/* <CInvalidFeedback className="help-block">* Must be 10 digits</CInvalidFeedback>                                 */}
                        </CFormGroup>

                        <CFormGroup>
                            <CLabel htmlFor="password">Password</CLabel>
                            <CInput type="password" onChange={ handleFormInputChange } name="password"  value={ password } className="form-control-success" id="password" required placeholder="Enter Your Password"/>
                            <CValidFeedback>Looks good</CValidFeedback>
                            <CInvalidFeedback className="help-block">Please provide your password</CInvalidFeedback>
                        </CFormGroup>

                        {
                          message ?
                            <CFormGroup className="text-center">
                                { message }
                            </CFormGroup>
                            : null
                        }

                        <div className="my-2"><a href="https://greatftech.com/auth/password/forgot?redirUrl=https://data.greatftech.com/login" target="_blank" rel="noopener noreferrer" >Forgot Password?</a></div>

                        <CFormGroup className="text-center">
                            <button disabled = { isLoginIn} className="btn btn-success btn-lg col-sm-10">
                              {
                                isLoginIn ? 'Authenticating...' : 'Login'
                              }
                            </button>
                        </CFormGroup>

                        <div className="my-2 text-right">Don't have an account? 
                          {
                            setShowLogin ? 
                              <Link onClick={()=> { setShowLogin(false); setShowRegister(true) }} to="#"><strong>&nbsp; Sign Up Now</strong></Link>                             
                            :
                              <Link to="/register"><strong>&nbsp; Sign Up Now</strong></Link>
                          }
                        </div>
                    </CForm>
                </CCardBody>
            </CCard>
        </CCol>    

        {
            toastMessage && <Toast params={{ type: 'success', message: toastMessage }}/>
        }
    </CRow>
  )
}

export default QuickLoginForm;
