import { createSlice } from '@reduxjs/toolkit';
const initialState = [];
  
export const airtelDataSlice = createSlice({
  name: 'airtelData',
  initialState,
  reducers: {
    airtelData: (state = initialState, {payload}) => {
      return [
        ...payload
      ]
    },
  },
})

export const { airtelData } = airtelDataSlice.actions;

export default airtelDataSlice.reducer;