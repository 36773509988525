import React from 'react';
import MissionStatement from './missionStatement';
import VisionStatement from './visionStatement';
import WhyUs from './whyUs';

const AboutUs = () => {
    return (
        <div className="bg-light text-dark  p-2 text-center">
            <WhyUs />
            <MissionStatement />
            <VisionStatement />
        </div>
    );
} 

export default AboutUs;