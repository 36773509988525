import {useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import QuickLoginForm from '../auth/quickLoginForm';
import { CCard, CCardBody, CCardHeader, CCol, CForm, CFormGroup, CValidFeedback, CInvalidFeedback, CInput, CLabel, CRow, CSelect } from '@coreui/react'
import WalletBalance from '../wallet/walletBalance';
import { network } from '../../redux/networkSlice';

const BuyAirtime = (props) => {
    const dispatch = useDispatch();
    
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState('');
    const networks = useSelector((state)=> state.networks);
    const [canValidatePhoneNo, setCanValidatePhoneNo] = useState(true);
    const [selNetworkName, setSelNetworkName] = useState('');
    const [show, setShow] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    
    const user = useSelector((state)=> state.user);

    const mtnNumbersPrefixes = ['07025', '07026', '0703', '0704', '0706', '0803', '0806', '0810', '0813', '0814', '0816', '0903', '0906'];
    const gloNumbersPrefixes = ['0805', '0807', '0705', '0815', '0811', '0905'];
    const airtelNumbersPrefixes = ['0802', '0808', '0708', '0812', '0701', '0902'];
    const nineMobileNumberPrefixes = ['0809', '0818', '0817', '0909'];

    const [inputField , setInputField] = useState({
        airtimePhoneNo: '',
        amount: '',
        airtimeType: 'VTU'
    })

    useEffect(()=>{
        const loadNetworks = async () =>{        
            try{
                setIsLoading(true);
    
                const res = await axios 
                .get(`${process.env.REACT_APP_API_PATH}network`);
                setIsLoading(false);
    
                const {error, data } = res.data;
        
                if(!error && data && Array.isArray(data)){
                    dispatch(network(data));
                }
            }catch(err){
                setIsProcessing(false);
                console.error(err);
            }
        }

        if(networks.length < 1){
            loadNetworks();  
        }    
    }, [networks.length, dispatch]);



    const inputChangeHandler = (event) => {
        const { name, value } = event.target;

        setInputField((prevState) => ({
          ...prevState,
          [name]: value,
        }));   

        if(name === 'byPassApiPhoneValidation'){
            setCanValidatePhoneNo(!canValidatePhoneNo);
        }

        if(name === 'selNetworkId'){            
            setSelNetworkName(networks.filter((network)=> network.id === parseInt(value))[0].name);
        }
    }

    const formSubmitHandler = async (event) => {
        event.preventDefault();
        setMessage('');
        let { airtimePhoneNo, amount, airtimeType, selNetworkId } = inputField;

        const prefix1 = airtimePhoneNo.substring(0, 4).trim();
		const prefix2 = airtimePhoneNo.substring(0, 3).trim();
	  
		if(prefix1 === '234'){
			airtimePhoneNo = '0'+airtimePhoneNo.substring(4, airtimePhoneNo.length);
		}else if(prefix2 === '234'){
            airtimePhoneNo = '0'+airtimePhoneNo.substring(3, airtimePhoneNo.length);
		}
	  
        if(inputField.airtimePhoneNo.length < 9){
            return setMessage('Phone number too short');
        } 

        if(!user){
            setShow(true);
            return;
        }

        if(!window.confirm(`NGN ${amount} airtime will be sent to ${airtimePhoneNo} from your wallet. Is this OK?`)){
            return;
        }    
        
        try{
            setIsLoading(true);
    
            const res = await axios 
            .post(`${process.env.REACT_APP_API_PATH}airtime/buyAirtime`, {
                phoneNumber: airtimePhoneNo, amount, airtimeType, networkId: selNetworkId, canValidatePhoneNo
            });
            setIsLoading(false);

            const { error, message, data } = res.data;
            setMessage(message);

            if(!error){
                window.alert(message);
                props.history.push(`/transaction/receipt/${data.transRef}`); // redirect to receipt page             
            }

        }catch(err){
            setIsLoading(false);
            const msg = err?.response?.data?.message || err.message;
            setMessage(msg);
            console.error(err);            
        }                    
    }    

    let {airtimePhoneNo, } = inputField;

    if(airtimePhoneNo.length > 9){
        const phoneNoPrefix = airtimePhoneNo.substring(0, 4);
        if(selNetworkName === 'MTN' && !mtnNumbersPrefixes.includes(phoneNoPrefix)){
            setMessage('This number does not look like MTN number. You can continue if you\'re sure');
        }else if(selNetworkName === 'GLO' && !gloNumbersPrefixes.includes(phoneNoPrefix)){
            setMessage('This number does not look like GLO number. You can continue if you\'re sure');
        }else if(selNetworkName === 'AIRTEL' && !airtelNumbersPrefixes.includes(phoneNoPrefix)){
            setMessage('This number does not look like AIRTEL number. You can continue if you\'re sure');
        }else if(selNetworkName === '9MOBILE' && !nineMobileNumberPrefixes.includes(phoneNoPrefix)){
            setMessage('This number does not look like 9Mobile number. You can continue if you\'re sure');
        }
    }

    if(isLoading){
        return <Skeleton count={3} />
    }
    
    return (
        <CRow>
            <CCol sm="9 mx-auto">
                <CCard>
                    <CCardHeader><h2 className="text-center">BUY AIRTIME</h2></CCardHeader>
                    <CCardBody>
                        <CForm className="was-validated" onSubmit={ formSubmitHandler } > 
                        
                        {
                            user && <WalletBalance />
                        }

                            <div className="row">
                                <CFormGroup className="col-sm-6">
                                    <CLabel htmlFor="network">Network</CLabel>
                                        <CSelect name="selNetworkId" multiple={false} onChange={ (e)=>{ inputChangeHandler(e);} } custom required id="network">
                                            <option value="">Select</option>
                                            {
                                                networks && Array.isArray(networks) && networks.length > 0 
                                                ? 
                                                networks.map((network)=> <option key={network.id} value={ network.id }>{ network.name }</option> ) 
                                                : 
                                                null
                                            }
                                        </CSelect>
                                    <CInvalidFeedback className="help-block">Please select Network</CInvalidFeedback>
                                    <CValidFeedback>Looks Good</CValidFeedback>
                                </CFormGroup> 
                            
                                <CFormGroup className="col-sm-6">
                                    <CLabel htmlFor="airtimeType">Type </CLabel>
                                    <CSelect name="airtimeType" multiple={false} onChange={ inputChangeHandler } custom required id="airtimeType">
                                        <option value="">Select</option>
                                        <option value="VTU">VTU</option>
                                        <option value="awuf4U">awuf4U</option>
                                        <option value="Share and Sell">Share and Sell</option>
                                    </CSelect>
                                    <CInvalidFeedback className="help-block">Please select type</CInvalidFeedback>
                                    <CValidFeedback>Looks Good</CValidFeedback>
                                </CFormGroup> 
                            </div>
                        
                            <CFormGroup>
                                <CLabel htmlFor="phoneNo">Phone Number</CLabel>
                                <CInput name="airtimePhoneNo" value={ inputField.airtimePhoneNo } id="phoneNo"  onChange={ inputChangeHandler } className="form-control-success" required placeholder="08012345678"/>
                                <CValidFeedback>Looks good</CValidFeedback>
                                <CInvalidFeedback className="help-block">Please provide phone number to recieve the airtime</CInvalidFeedback>
                            </CFormGroup>

                            <CFormGroup>
                                <CLabel htmlFor="amount">Amount</CLabel>
                                <CInput type="text" onChange ={ inputChangeHandler } name="amount" value={ inputField.amount } className="form-control-success" id="amount" required placeholder="500"/>
                                <CValidFeedback>Looks good</CValidFeedback>
                                <CInvalidFeedback className="help-block">Please enter amount</CInvalidFeedback>
                            </CFormGroup>

                            <div className="form-group">
                                <label htmlFor="canValidatePhoneNumber">
                                <input type="checkbox" onChange={ inputChangeHandler } checked={ canValidatePhoneNo } name="byPassApiPhoneValidation"  id="canValidatePhoneNumber" value={canValidatePhoneNo} />
                                    <strong> Validate this number:</strong>
                                </label>                                        
                            </div>                        

                            { message ? <CFormGroup className="text-center">{ message }</CFormGroup> : null }

                            <CFormGroup className="text-center">
                                <button disabled = { isProcessing } className="btn btn-success btn-lg col-sm-10">
                                {
                                    isProcessing ? 'Processing...' : 'Send Airtime'
                                }
                                </button>
                            </CFormGroup>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCol>                 

            <Modal
                show={show}
                onHide={()=> setShow(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                <Modal.Title>Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <QuickLoginForm setShowModal={ () => setShow(false)} />
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={()=> setShow(false)}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
    </CRow>
    );
 }

export default withRouter(BuyAirtime);
