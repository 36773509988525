import React from 'react';
import { useSelector } from 'react-redux'

const PaymentInstructions = () => {
    const merchant = useSelector((state)=> state.merchant);    

    return (
        <div>                
            <div>
                <h2>You can also transfer to us if you are having issues with the manual funding</h2>
            </div>
            <div>
                <ul>
                    <li>
                        Make a transfer of the wallet funding amount to the bank details below
                        <div className="my-3">
                            <div>
                                Account Number:
                                <strong>{merchant.accountNumber}</strong>
                            </div>
                            
                            <div>
                                Account Name: 
                                <strong>{merchant.accountName}</strong>
                            </div>

                            <div>
                                Bank Name: 
                                <strong>{merchant.bankName}</strong>
                            </div>                                                                
                        </div>

                    </li>
                    <div
                        >After Successful transfer, whatsapp or send sms with your payment details to us via {merchant.supportWhatsapp}.
                    </div>
                </ul>
            </div>

        </div>
    );
    
}

export default PaymentInstructions;