import { createSlice } from '@reduxjs/toolkit';
const initialState = [];
  
export const referrerSlugSlice = createSlice({
  name: 'referralSlug',
  initialState,
  reducers: {
    referralSlug: (state = initialState, action) => {
      return [
        ...action.payload
      ]
    },
  },
})

export const { referralSlug } = referrerSlugSlice.actions;

export default referrerSlugSlice.reducer;