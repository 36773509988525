import {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const News = () => {
    // const [isLoading, setIsLoading] = useState(false);
    const [news, setNews] = useState([]);

    useEffect(()=>{
        loadNews();
    },[]);

    const loadNews = async () => {
        try{
            // setIsLoading(true);
            const res = await axios.get(`${process.env.REACT_APP_API_PATH}news/getCurrentNews`);
            const { error, data} = res.data;

            if(!error && Array.isArray(data)){
                setNews(data);
            }
        }catch(err){
            console.error(err);
            // setIsLoading(false);
        }
    }

    return (
        news.map((each)=>
            <div key={each.id} className="row m-0 col-12 border my-2 py-2" style={{background: '#eee'}}>
                <div className="col-10 text-uppercase">
                    <strong>{ each.news}</strong>
                </div>
                <div className="col-2"><Link to={ `/news/${each.slug}` }>LEARN MORE</Link></div>
            </div>
        )
    );
}

export default News;