import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Loading from '../helper/loading';
import PersonFeedback from './personFeedback';

const PeopleFeedback = () => {
    const [feedbacks, setFeedBacks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        loadFeedBacks();
    }, []);

    const loadFeedBacks = async () => {
        try{
            setIsLoading(true);
            const res = await axios.get(`${process.env.REACT_APP_API_PATH}users/getUsersFeedback`);
            setIsLoading(false);
            const { error, data } = res.data;
      
            if(!error){
              setFeedBacks(data);
            }
        }catch(err){
            console.error(err);
        }
    }

    return (
        <div className="my-3" style={{background: '#fff'}}>

            { isLoading && <Loading params={{msg: 'loading feedbacks...'}} /> }

            { feedbacks.length > 0 && 
                <div className="row m-0 ">
                    { feedbacks.map((feedback)=> 
                        <div key={feedback.id} className="col-sm-4">
                            <PersonFeedback params={feedback} />
                        </div>) 
                    }
                </div>
            }
        </div>
    );
}

export default PeopleFeedback;