import { createSlice } from '@reduxjs/toolkit';
const initialState = null;
  
export const merchantSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    merchant: (state = initialState, action) => {
      return  action.payload      
    },
  },
})

export const { merchant } = merchantSlice.actions;

export default merchantSlice.reducer;