import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { Carousel } from 'react-carousel-minimal';
import { useDispatch, useSelector } from 'react-redux';
import { slider } from 'src/redux/slidersSlice';
import APK from './apk/APK';

const Sliders =  () => {
  const dispatch = useDispatch();
  const sliders = useSelector((state)=> state.sliders);

  useEffect(()=>{
    const loadSliders = async () => {
      try{
        const res = await axios.get(`${process.env.REACT_APP_API_PATH}application/getSliders`);
  
        const { error, data } = res.data;
  
        if(!error){
          dispatch(slider(data));
        }
  
      }catch(err){
        console.error(err);
      }
    }
    
    if(sliders.length < 1){
      loadSliders();
    }
  },[dispatch, sliders]);

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }

  return (
    <div className="text-center">
        <h3 className="px-3">NEED DATA AT AFFORDABLE PRICES? </h3>
        <p>
            <strong>
                We gat your back!
              </strong>
              &nbsp;We are here to give you enough data every day 
          </p>

          <APK />

        <div style={{
        padding: "0 20px"
        }}>

          { sliders.length > 0 && 
            <Carousel
                  data={sliders}
                  time={1000}
                  width="850px"
                  height="500px"
                  captionStyle={captionStyle}
                  radius="10px"
                  slideNumber={true}
                  slideNumberStyle={slideNumberStyle}
                  captionPosition="bottom"
                  automatic={true}
                  dots={true}
                  pauseIconColor="white"
                  pauseIconSize="40px"
                  slideBackgroundColor="darkgrey"
                  slideImageFit="cover"
                  thumbnails={true}
                  thumbnailWidth="100px"
                  style={{
                  textAlign: "center",
                  maxWidth: "850px",
                  maxHeight: "500px",
                  margin: "40px auto",
                }}
            />
          }

        </div>
    </div>
  );
}

export default Sliders;